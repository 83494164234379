<div class="dialog-remove-from-saved">
    <h1 mat-dialog-title>Remove tune from feed?</h1>
    
      <div class="dialog-remove-from-saved__warning">
        Are you sure you want to remove this tune from your feed?
      </div>
    
      <div mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-flat-button color="danger" [mat-dialog-close]="tune" cdkFocusInitial
          class="dialog-remove-from-saved__delete-button">
          <span>Remove</span>
        </button>
      </div>
</div>
