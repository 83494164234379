import { NgModule } from '@angular/core';
import { LinkHttpPipe } from './link-http/link-http';
import { OrderByPipe } from './order-by.ts/order-by';
import { ReplaceTextPipe } from './replace-text/replace-text';
import { SearchArtistsPipe } from './search-artists/search-artists';

const Pipes = [LinkHttpPipe, ReplaceTextPipe, OrderByPipe, SearchArtistsPipe];

@NgModule({
    declarations: [...Pipes],
	imports: [],
	exports: [...Pipes]
})
export class PipesModule {}
