<div class="dialog-report">
  <div [formGroup]="reportTypes">
    <div class="dialog-report__title">Why do you report this tune?</div>
    <p><mat-checkbox color="primary" formControlName="spam">It is spam</mat-checkbox></p>
    <p><mat-checkbox color="primary" formControlName="inappropriate">It is inappropriate</mat-checkbox></p>
    <p *ngIf="tune"><mat-checkbox color="primary" formControlName="notmusic">This is not music</mat-checkbox></p>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancel</button>

    <button mat-flat-button color="primary" [mat-dialog-close]="[data, reportTypes.value]" cdkFocusInitial
      class="dialog-report__report-button">
      <span>Report</span>
    </button>
  </div>
</div>
