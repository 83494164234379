<div class="navigation-search">

  <div class="navigation-search__mobile-input visible-s" *ngIf="mobileInputVisible">
    <input class="navigation-search__mobile-input__input" (click)="openResults($event)" (ngModelChange)="inputChange($event)"
      [(ngModel)]="searchQuery" placeholder="Search Tunr">
    <mat-icon class="navigation-search__mobile-input__input__search">search</mat-icon>
    <button class="navigation-search__mobile-input__input__close" mat-icon-button
      (click)="hideMobileInput()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="hidden-s">
    <input class="navigation-search__input" (click)="openResults($event)" (ngModelChange)="inputChange($event)"
      [(ngModel)]="searchQuery" placeholder="Search Tunr">
    <mat-icon class="navigation-search__input__search">search</mat-icon>
    <button class="navigation-search__input__close" *ngIf="(searchQuery?.length || 0) > 0" mat-icon-button
      (click)="clearSearchQuery()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="visible-s">
    <button mat-icon-button (click)="showMobileInput()">
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <mat-card class="navigation-search__results" (clickOutside)="closeResults()" *ngIf="showResults">
    <div class="navigation-search__results__loading"
      [ngClass]="{'hide': !searchLoading || searchQuery.length < 1 || noSearchResults}">
      Searching for '{{searchQuery}}'...
    </div>

    <div class="navigation-search__results__no-results" *ngIf="noSearchResults">
      No results found for {{searchQuery}}
    </div>

    <div class="navigation-search__results__empty" *ngIf="searchQuery.length === 0">
      Search for users or hashtags
    </div>

    <div [ngClass]="{'hide': searchQuery.length < 1 || searchLoading}" *ngFor="let item of searchList | slice:0:10">

      <div *ngIf="!item?.isHashtag" (click)="goTo('user', item?.username)">
        <app-users-list-user [user]="item"></app-users-list-user>
      </div>

      <div class="navigation-search__results__hashtag" matRipple *ngIf="item?.isHashtag" (click)="goTo('hashtag', item?.hashtagId)">

        <div class="navigation-search__results__hashtag__icon">
          <i class="fas fa-hashtag"></i>
        </div>

        <div class="navigation-search__results__hashtag__copy">
          <div class="navigation-search__results__hashtag__copy__name">
            #{{item?.hashtagId}}
          </div>
          <div class="navigation-search__results__hashtag__copy__count">
            {{item?.hashtagsCount}} tunes posted
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
