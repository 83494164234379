import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Tune } from 'src/app/features/shared/interfaces/tune';
import { SubSink } from 'subsink';
import { AuthService } from '../../services/auth/auth.service';
import { YoutubeVideoService } from '../../services/youtube-video/youtube-video.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss'],
  animations: [
    trigger('visibilityYoutubeVideo', [
      state('shown', style({
        display: 'block'
      })),
      state('hidden', style({
        display: 'none'
      })),
      transition('shown => hidden', [
        style({ transform: 'translateY(10%)' }),
        animate(10)
      ]),
      transition('hidden => shown', [
        style({ transform: 'translateY(-10%)' })
      ])
    ])
  ]
})
export class YoutubeVideoComponent implements OnInit {

  private subs = new SubSink();

  isServer: boolean = true;

  uid!: string | null;
  activeTune!: Tune;
  player!: any;
  youtubeState!: number; // -1: unstarted | 0: ended | 1: playing | 2: paused | 3: buffering | 5: video cued
  mediaplayerInitialized: boolean = false;
  mediaplayerVisible: boolean = false;
  mediaplayerInterval!: any;
  updateProgressInterval!: any;
  visibilityYoutubeVideo: string = 'hidden';
  showMediaplayerLarge: boolean = false;

  constructor(
    private youtubeVideoService: YoutubeVideoService,
    private authService: AuthService, 
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this.isServer = isPlatformServer(platformId);

    this.subs.add(this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    }))
  }

  ngOnInit(): void {
    if (!this.isServer) {
      this.initializeMediaplayer();
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url && event.url.startsWith('/auth')) {
          if (this.player) this.player.pauseVideo();
          this.visibilityYoutubeVideo = 'hidden';
        }
      }
    });

    this.subs.add(this.youtubeVideoService.activeTune$.subscribe((activeTune: Tune) => {
      this.activeTune = { ...activeTune };
    }));

    this.subs.add(this.youtubeVideoService.playById$.subscribe((activeTune: Tune) => {
      // Start the mediaplayer if it is initialized. Otherwise, poll every 200ms to wait for it to be initialized
      if (this.mediaplayerInitialized) {
        this.playById(activeTune);
      } else {
        this.mediaplayerInterval = setInterval(() => {
          this.waitForMediaplayer(activeTune);
        }, 200)
      }
    }));

    this.subs.add(this.youtubeVideoService.playPause$.subscribe(() => {
      if (this.youtubeState === 1) {
        this.player.pauseVideo();
      } else if (this.youtubeState === 2) {
        if (!this.mediaplayerVisible) {
          //TODO
          //this.showMediaplayerAnimation();
        }
        this.player.playVideo();
        this.visibilityYoutubeVideo = 'shown';
        this.youtubeVideoService.mediaplayerVisible(true);
      }
    }));

    this.subs.add(this.youtubeVideoService.seekToSubject$.subscribe(
      (value) => {
        const timePercentage = value / 100;
        const newTime = timePercentage * this.player.getDuration();
        this.player.seekTo(newTime);
        this.youtubeVideoService.updateActiveTune({...this.activeTune, progress: value});

        // Stop updating the progress for a while
        clearInterval(this.updateProgressInterval);
        setTimeout(() => {
          this.updateProgressInterval = setInterval(this.updateProgress.bind(this), 300);
        }, 1500);
      }
    ));

    this.youtubeVideoService.youtubeVolumeSubject$.subscribe(
      (value) => {
        this.player.setVolume(value)
      }
    );

    this.youtubeVideoService.youtubeVolumeOnSubject$.subscribe(
      () => {
        this.player.unMute();
      }
    );

    this.youtubeVideoService.youtubeVolumeOffSubject$.subscribe(
      () => {
        this.player.mute();
      }
    );

    this.youtubeVideoService.checkMediaplayerSizeObs$.subscribe(
      (open: boolean) => {
        this.showMediaplayerLarge = open;

        if (!this.isServer) {
          if (window.innerWidth < 960) {
            if (open) {
              this.document.body.classList.add('stop-scroll');
            } else {
              this.document.body.classList.remove('stop-scroll');
            }
          } else {
            this.document.body.classList.remove('stop-scroll');
          }
        }
      }
    );

    this.youtubeVideoService.mediaplayerVisible$.subscribe(
      (visible) => {
        this.mediaplayerVisible = visible;
      }
    );

    this.youtubeVideoService.closeMediaplayerObs$.subscribe(
      () => {
        if (!this.isServer) {
          this.player.pauseVideo();
          this.visibilityYoutubeVideo = 'hidden';
          this.mediaplayerVisible = false;
        }
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  expandMediaplayer = () => {
    this.youtubeVideoService.expandMediaplayer();
  }

  initializeMediaplayer = () => {
    if (!this.isServer) {
      (<any>window).onYouTubeIframeAPIReady = () => {
        this.player = new (<any>window).YT.Player('ytplayer', {
          height: '100%',
          width: '100%',
          playerVars: {
            autoplay: 0,
            rel: 0,
            controls: 0,
            color: 'white',
            enablejsapi: 1,
            showinfo: 0,
            playsinline: 1,
            origin: "https://www.youtube.com",
            iv_load_policy: 3
          },
          events: {
            'onReady': () => {
              this.initializePlayer();
            },
            'onStateChange': (e: any) => {
              this.onPlayerStateChange(e);
            },
            'onError': (e: any) => {
              this.showError(e);
            }
          }
        });
      };
    }
  }

  ngAfterViewInit() {
    if (!this.isServer) {
      const doc = (<any>window).document;
      let playerApiScript = doc.createElement('script');
      playerApiScript.type = 'text/javascript';
      playerApiScript.src = 'https://www.youtube.com/iframe_api';
      doc.body.appendChild(playerApiScript);
    }
  }

  initializePlayer = () => {
    this.mediaplayerInitialized = true;

    // Start interval to update elapsed time display and the elapsed part of the progress bar every 300 milliseconds.
    this.updateProgressInterval = setInterval(this.updateProgress.bind(this), 300);
  };

  onPlayerStateChange = (e: any) => {
    this.youtubeState = e.data;
    this.youtubeVideoService.changeYoutubeState(this.youtubeState);

    const currentTime = this.player.getCurrentTime();
    const duration = this.player.getDuration();

    if (this.youtubeState === 0 && currentTime && duration && duration !== 0) {
      if ((currentTime >= this.activeTune.endSec) || (currentTime >= duration)) {
        this.youtubeVideoService.tuneEnded();
      }
    }
  }

  showError = (e: any) => {
    if (e.data == '150' || e.data == '101') {
      console.log('error')
      //this.showUnavailableToast();
    }
  }

  playById = (activeTune: Tune) => {
    this.visibilityYoutubeVideo = 'shown';
    this.youtubeVideoService.mediaplayerVisible(true);

    if (activeTune.type === 'snippet') {
      this.player.loadVideoById({
        videoId: activeTune.uploadUrl,
        startSeconds: activeTune.startSec,
        endSeconds: activeTune.endSec
      })
    } else {
      this.player.loadVideoById({
        videoId: activeTune.uploadUrl
      })
    }
  }

  waitForMediaplayer = (activeTune: Tune) => {
    if (this.mediaplayerInitialized) {
      this.playById(activeTune);
      clearInterval(this.mediaplayerInterval);
    }
  }

  updateProgress = () => {
    // Update the value of our progress bar accordingly. Execute only when getDuration() is more than 0
    if (this.player.getDuration() > 0 && this.youtubeState === 1) {

      const progress = (this.player.getCurrentTime() / this.player.getDuration()) * 100;
      const snippetProgress = (((this.player.getCurrentTime() - this.activeTune?.startSec) / (this.activeTune?.endSec - this.activeTune?.startSec)) * 100);
      const currentTime = this.formatTime(this.player.getCurrentTime());
      const duration = this.formatTime(this.player.getDuration())

      if (this.activeTune?.type === 'snippet') {
        this.youtubeVideoService.updateProgress(currentTime, duration, progress, snippetProgress);
      } else if (this.activeTune?.type === 'full') {
        this.youtubeVideoService.updateProgress(currentTime, duration, progress);
      }
    }
  }

  // Update the format of the current time
  formatTime = (time: number): string => {
    time = Math.round(time);

    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    const secondsImproved = seconds < 10 ? '0' + seconds : seconds;

    return minutes + ":" + secondsImproved;
  }
}
