import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { TimeagoModule } from 'ngx-timeago';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

// UI Components
import { TunesListComponent } from './components/tunes-list/tunes-list.component';
import { TuneComponent } from './components/tune/tune.component';
import { TuneInButtonComponent } from './components/tune-in-button/tune-in-button.component';
import { NotificationComponent } from './components/notification/notification.component';
import { RemoveFromSavedComponent } from './dialogs/remove-from-saved/remove-from-saved.component';
import { DeleteFromFeedComponent } from './dialogs/delete-from-feed/delete-from-feed.component';
import { ReportComponent } from './dialogs/report/report.component';
import { CommentComponent } from './components/comment/comment.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UserComponent } from './components/user/user.component';
import { UseAppComponent } from './components/use-app/use-app.component';

// Dialogs
import { EditTuneComponent } from './dialogs/edit-tune/edit-tune.component';
import { EditRepostDescriptionComponent } from './dialogs/edit-repost-description/edit-repost-description.component';
import { DeleteTuneComponent } from './dialogs/delete-tune/delete-tune.component';
import { EditBuyinfoComponent } from './dialogs/edit-buyinfo/edit-buyinfo.component';
import { ShareComponent } from './dialogs/share/share.component';
import { SearchExternalComponent } from './dialogs/search-external/search-external.component';
import { RepostComponent } from './dialogs/repost/repost.component';
import { LikesTotalComponent } from './dialogs/likes-total/likes-total.component';
import { DeleteCommentComponent } from './dialogs/delete-comment/delete-comment.component';

// Directives
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { AuthRequiredComponent } from './dialogs/auth-required/auth-required.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const SharedComponents = [TunesListComponent, TuneComponent, TuneInButtonComponent, UsersListComponent, UserComponent, NotificationComponent, CommentComponent, UseAppComponent];
const SharedDialogs = [EditTuneComponent, EditRepostDescriptionComponent, DeleteTuneComponent, EditBuyinfoComponent, ShareComponent, SearchExternalComponent, RepostComponent, RemoveFromSavedComponent, DeleteFromFeedComponent, ReportComponent, DeleteCommentComponent, LikesTotalComponent];
const Directives = [ClickOutsideDirective];

@NgModule({
  declarations: [...SharedComponents, ...SharedDialogs, ...Directives, AuthRequiredComponent],
  exports: [...SharedComponents, ...SharedDialogs, ...Directives],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PipesModule,
    TimeagoModule,
    ShareButtonsModule,
    ShareIconsModule,
    InfiniteScrollModule
  ]
})
export class SharedModule { }
