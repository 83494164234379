import { Component, Input, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SubSink } from 'subsink';
import { Notification } from '../../interfaces/notification';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  private subs = new SubSink();

  @Input() notification!: Notification;

  uid!: string | null;
  isLoggedIn!: boolean;

  constructor(
    private authService: AuthService,
    private db: AngularFireDatabase,
    private router: Router
  ) {
    this.subs.add(this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    }))
  }

  ngOnInit() {
    this.getImage();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  stopRouterLink = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  }

  onClickNotification = () => {
    this.removeDidNotSee();
    this.goTo();

    setTimeout(() => {
      this.notification.didnotsee = false;
    }, 100);
  }

  removeDidNotSee() {
    if (this.notification.type === 'newTuner') {
      this.db.object(`/notificationsTune/${this.uid}/${this.notification.author}/didnotsee`).set(null);
    } else if (this.notification.type === 'newNotification') {
      this.db.object(`/notifications/${this.uid}/${this.notification.originalKey}/didnotsee`).set(null);
    }
  }

  goTo = () => {
    if (this.notification.type === 'newNotification') {
      this.router.navigate([`/tunes/${this.notification.key}`]);
    } else if (this.notification.type === 'newTuner') {
      this.router.navigate([`/profile/${this.notification.username}`]);
    } else if (this.notification.type === 'activity') {
      let path: string;
      let id: string | undefined;

      if (this.notification.tunedin) {
        path = 'user';
        id = this.notification.otherUsername;
        this.router.navigate([`/profile/${id}`]);
      } else if (this.notification.liked || this.notification.comment || this.notification.commentedOnTune) {
        path = 'tune';
        id = this.notification.key;
        this.router.navigate([`/tunes/${id}`]);
      }
    }
  }
  // goTo = () => {
  //   let path: string;
  //   let id: string;

  //   if (this.notification.type === 'newNotification') {
  //     path = !this.notification.reply ? 'tune' : 'comment'; 
  //     id = this.notification.key;
  //   } else if (this.notification.type === 'newTuner') {
  //     path = 'user';
  //     id = this.notification.username;
  //   } else if (this.notification.type === 'activity') {
  //     if (this.notification.tunedin) {
  //       path = 'user';
  //       id = this.notification.otherUsername;
  //     } else if (this.notification.liked || this.notification.comment || this.notification.commentedOnTune) {
  //       path = 'tune';
  //       id = this.notification.key;
  //     }
  //   }
  //   this.navCtrl.navigateForward(`/tabs/${this.activeTab}/${path}/${id}`);
  // }


  getImage = () => {
    let author = (this.notification?.type === 'newNotification') ? this.notification?.latestUser?.author : this.notification?.author;

    this.subs.add(this.db.object(`/images/${author}/image`).valueChanges().subscribe((val: any) =>
      this.notification.image = val
    ));
  }
}
