import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TransferDataService } from 'src/app/core/services/transfer-data/transfer-data.service';

@Component({
  selector: 'app-auth-required',
  templateUrl: './auth-required.component.html',
  styleUrls: ['./auth-required.component.scss']
})
export class AuthRequiredComponent implements OnInit {

  action: string = "";

  constructor(
    private dialogRef: MatDialogRef<AuthRequiredComponent>,
    private router: Router,
    private transferDataService: TransferDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
      const type = data.type;

      if (type === 'comment') {
        this.action = 'comment on a tune';
      } else if (type === 'tuneIn') {
        this.action = 'tune in into someone'
      } else {
        this.action = `${type} a tune`;
      }
    }

  ngOnInit(): void {
  }

  goTo = (path: string) => {
    this.router.navigate([`/auth/${path}`]);
    this.close();
    this.transferDataService.closeDialog();
}

  close(): void {
    this.dialogRef.close();
  }
}
