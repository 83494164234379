<div class="navigation-notifications">
    <div class="navigation-notifications__button">
      <button mat-icon-button [matMenuTriggerFor]="notificationsMenu" (click)="removeNotificationsCount()" aria-label="Show notifications">
        <mat-icon [matBadge]="notificationsCount" matBadgeColor="warn" [matBadgeHidden]="notificationsCount < 1">notifications</mat-icon>
      </button>
    </div>
  
    <mat-menu class="navigation-notifications__results" #notificationsMenu="matMenu">
      <div class="navigation-notifications__results__header">
        <div class="navigation-notifications__results__header__title">Notifications</div>
        <div class="navigation-notifications__results__header__icon" *ngIf="!noNotifications">
          <button mat-icon-button class="navigation-notifications__results__header__icon" (click)="$event.stopPropagation()"
            #notificationOptionsTrigger="matMenuTrigger" [matMenuTriggerFor]="notificationSettings" aria-label="Open notifications settings dialog">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </div>
      </div>
  
      <div class="navigation-notifications__results__loading" *ngIf="loading">
          <mat-progress-spinner color="primary" mode="indeterminate" strokeWidth="4" diameter="40" aria-label="Loading spinner"></mat-progress-spinner>
      </div>

      <div class="navigation-notifications__results__no-notifications" *ngIf="noNotifications">
        You have no notifications yet
      </div>
  
      <div [ngClass]="{'hide': loading || noNotifications}">
        <div mat-menu-item class="navigation-notifications__results__item" *ngFor="let notification of notificationsList | async | orderBy: 'date' | slice:0:5">
          <app-notification [notification]="notification"></app-notification>
        </div>

        <div class="navigation-notifications__results__see-all" matRipple routerLink="/notifications">
          See all
        </div>
      </div>
  
      <mat-menu #notificationSettings="matMenu" xPosition="before">
        <button mat-menu-item (click)="markAllAsRead($event)" aria-label="Mark all notifications as read">
          <mat-icon>check</mat-icon>
          <b>Mark all as read</b>
        </button>
      </mat-menu>
    </mat-menu>
  </div>
  