import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'replaceText'})
export class ReplaceTextPipe implements PipeTransform {
  transform(value: string): string {
    if(value) {
        return value.replace(/\*\*\*/g, '<br><br>').replace(/\*\*/g, '<br>');
    } else {
        return value;
    }
  }
}