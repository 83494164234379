import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  isPlatformBrowser!: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { 
    this.isPlatformBrowser = isPlatformBrowser(this.platformId);
  }

  isBrowser = () => {
    return this.isPlatformBrowser;
  }

}
