import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NotificationsService } from 'src/app/core/services/notifications/notifications.service';
import { SubSink } from 'subsink';
import { take, tap } from 'rxjs/operators';
import _first from 'lodash/first';
import _slice from 'lodash/slice';
import _concat from 'lodash/concat';
import { MatMenuTrigger } from '@angular/material/menu';
import { Notification } from 'src/app/features/shared/interfaces/notification';

@Component({
  selector: 'app-navigation-new-tuners',
  templateUrl: './new-tuners.component.html',
  styleUrls: ['./new-tuners.component.scss']
})
export class NewTunersComponent implements OnInit {

  private subs = new SubSink();

  @ViewChild('notificationOptionsTrigger') notificationOptionsTrigger!: MatMenuTrigger;
  
  uid!: string | null;
  notificationsList = new BehaviorSubject<Notification[]>([]);
  batch: number = 6;
  lastDate: number = 0;
  noNotifications: boolean = false;
  loading: boolean = true;

  notificationsCount: number = 0;

  constructor(
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private db: AngularFireDatabase,
  ) { 
    this.subs.add(this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;

      if (this.uid) {
        this.listenToNotifications();
        this.getNewTuners();
      } else {
        this.lastDate = 0;
        this.notificationsList.next([]);
      }
    }));
  }

  markAllAsRead = (event: any) => {
    event.stopPropagation();
    this.notificationOptionsTrigger.closeMenu();

    this.db.list(`/notificationsTune/${this.uid}`).valueChanges().pipe(take(1)).subscribe((notifications: any[]) => {
      notifications.forEach((notification: any) => {
        this.db.object(`/notificationsTune/${this.uid}/${notification.author}/didnotsee`).set(null);
      })
    })

    let list = this.notificationsList.getValue();
    list.forEach((notification: Notification) => {
      notification.didnotsee = false;
    });
  }

  ngOnInit(): void {
  }
  
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getNewTuners = (event?: any) => {
    this.subs.add(this.notificationsService.getNewTuners(this.batch, this.lastDate).pipe(
      tap((notifications: any[]) => {
        
        if (!notifications || notifications.length < 1) {
          this.noNotifications = true;
          this.loading = false;
          return;
        } else {
          this.noNotifications = false;
        }

        // Set the lastDate in preparation for the next query
        this.lastDate = _first(notifications)['date'];

        let newNotifications = [];

        if (notifications.length < this.batch + 1) {
          newNotifications = _slice(notifications, 0, this.batch);
        } else {
          newNotifications = _slice(notifications, 1, this.batch + 1);
        }

        // Get current notifications in BehaviorSubject
        const currentNotifications = this.notificationsList.getValue();

        // Check which notifications are new and only push those
        const currentAuthors = this.notificationsList.getValue().map((currentTune: any) => currentTune.author);
        newNotifications = newNotifications.filter(newNotif => !currentAuthors.includes(newNotif.author));
        const newAuthors = newNotifications.map((currentTune: any) => currentTune.author);
        const combinedAuthors = [...currentAuthors, ...newAuthors];

        // Check if there is a notification that is deleted. If so, remove from notifications
        combinedAuthors.forEach((author: string) => {
          this.subs.add(this.db.object(`/notificationsTune/${this.uid}/${author}`).valueChanges().subscribe(value => {
            if (!value) {
              this.deleteFromList(author);
            }
          }));
        });

        this.notificationsList.next(_concat(currentNotifications, newNotifications));

        setTimeout(() => {
          this.loading = false;
        }, 300);

        // Close the onScroll event
        if (event) {
          event.target.complete();
        }

      })
    ).subscribe());
  }

  deleteFromList = (author: string) => {
    let list = this.notificationsList.getValue();
    list.forEach((notification: any, index) => {
      if (notification.author === author) {
        list.splice(index, 1);
      };
    });
  }

  removeNotificationsCount = () => {
    this.db.list(`/notificationsTuneCount/${this.uid}`).remove();
  }

  listenToNotifications = async () => {
    this.subs.add(this.db.list(`/notificationsTuneCount/${this.uid}`).valueChanges().subscribe(notifications => {
      this.notificationsCount = notifications.length;
    }))
  }
}
