import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    pure: false
})
export class OrderByPipe implements PipeTransform {
    transform(value: any, type: string): any[] | null {
        if (value) {
            value = value.sort((a: any, b: any) => {
                return parseFloat(b[type]) - parseFloat(a[type]);
            });

            return value;
        } else {
            return null;
        }
    }
}
