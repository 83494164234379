<div class="mediaplayer-small visible-s" [ngClass]="{'mediaplayer-small-open': mediaplayerSmallOpen}"
  [@visibilityMediaplayer]="visibilityMediaplayer">
  <div class="mediaplayer-small__closed" *ngIf="!mediaplayerSmallOpen">

    <div class="mediaplayer-small__closed__expand" (click)="expandMediaplayer()">
      <button mat-icon-button aria-label="Expand mediaplayer">
        <mat-icon>expand_less</mat-icon>
      </button>
    </div>

    <div class="mediaplayer-small__closed__video"></div>

    <div class="mediaplayer-small__closed__title-wrapper" (click)="expandMediaplayer()">
      <div class="mediaplayer-small__closed__title-wrapper__title">
        {{ activeTune?.uploadTitle }}
      </div>
    </div>

    <div class="mediaplayer-small__closed__playpause" (click)="playTune()">
      <button mat-icon-button aria-label="Play or pause tune">
        <mat-icon [ngClass]="{'hide': activeTune?.youtubeState !== 2}">play_circle</mat-icon>
        <mat-icon [ngClass]="{'hide': activeTune?.youtubeState !== 1}">pause_circle</mat-icon>
        <mat-icon [ngClass]="{'hide': activeTune?.youtubeState !== 0}">refresh</mat-icon>
      </button>
    </div>
    <div class="mediaplayer-small__closed__close" (click)="closeMediaplayer()">
      <button mat-icon-button aria-label="Close mediaplayer">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="mediaplayer-small__open" *ngIf="mediaplayerSmallOpen">
    <div class="mediaplayer-small__open__top">
      <div class="mediaplayer-small__open__top__author" *ngIf="!activeTune?.isPreview">
        <div class="mediaplayer-small__open__top__author__image" (click)="goTo('user', activeTune?.name)">
          <img [src]="activeTune?.image" />
        </div>
        <div class="mediaplayer-small__open__top__author__name" (click)="goTo('user', activeTune?.name)">
          {{ activeTune?.name }}<span *ngIf="activeTune?.blog" class="fas fa-crown faside icon-crown"></span></div>
        <div class="mediaplayer-small__open__top__author__date"> &middot; {{ activeTune?.date | timeago:false }}</div>
        <div class="mediaplayer-small__open__top__author__minimize" (click)="minimizeMediaplayer()">
          <button mat-icon-button aria-label="Minimize mediaplayer">
            <mat-icon>expand_more</mat-icon>
          </button>
          <!-- <ion-fab-button mode="md"><i class="fab fa-youtube"></i></ion-fab-button> -->
        </div>
      </div>

      <div class="mediaplayer-small__open__top__author" *ngIf="activeTune?.isPreview">
        <div class="mediaplayer-small__open__top__author__minimize" (click)="minimizeMediaplayer()">
          <button mat-icon-button aria-label="Minimize mediaplayer">
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="mediaplayer-small__open__content">

      <div class="mediaplayer-small__open__content__title" *ngIf="!activeTune?.isPreview"
        (click)="goTo('tune', activeTune?.key)">
        {{ activeTune?.uploadTitle }}
      </div>

      <div class="mediaplayer-small__open__content__title" *ngIf="activeTune?.isPreview">
        {{ activeTune?.uploadTitle }}
      </div>

      <div class="mediaplayer-small__open__content__progress">
        <div class="mediaplayer-small__open__content__progress__current-time">
          {{ activeTune?.currentTime }}
        </div>
        <div class="mediaplayer-small__open__content__progress__progressbar">
          <mat-slider color="primary" min="1" max="100" step="1"
            *ngIf="activeTune?.progress || activeTune?.progress === 0" [(ngModel)]="activeTune.progress"
            (change)="onChangeSlider()" (input)="onInputChangeProgress($event)">
          </mat-slider>
        </div>
        <div class="mediaplayer-small__open__content__progress__duration">
          {{ activeTune?.duration }}
        </div>
      </div>

      <div class="mediaplayer-small__open__content__controls">
        <div class="mediaplayer-small__open__content__controls__previous" (click)="playPrevious()"
          *ngIf="!activeTune?.isPreview">
          <button mat-icon-button class="mediaplayer__progress__controls__previous" [disabled]="!hasPreviousTune"
            aria-label="Play previous tune">
            <mat-icon *ngIf="hasPreviousTune" inline="true">skip_previous</mat-icon>
            <mat-icon *ngIf="!hasPreviousTune" style="color:grey" inline="true">skip_previous</mat-icon>
          </button>
        </div>
        <div class="mediaplayer-small__open__content__controls__play" (click)="playTune()">
          <button mat-icon-button disableRipple="true" class="mediaplayer__progress__controls__playpause"
            (click)="playTune()" aria-label="Play or pause tune">
            <mat-icon inline="true" [ngClass]="{'hide': activeTune?.youtubeState !== 2}">play_circle</mat-icon>
            <mat-icon inline="true" [ngClass]="{'hide': activeTune?.youtubeState !== 1}">pause_circle</mat-icon>
            <mat-icon inline="true" [ngClass]="{'hide': activeTune?.youtubeState !== 0}">refresh</mat-icon>
            <mat-progress-spinner class="mediaplayer__progress__controls__playpause__spinner" mode="indeterminate"
              strokeWidth="3" diameter="32" aria-label="Loading tune spinner"
              *ngIf="activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5">
            </mat-progress-spinner>
          </button>
        </div>
        <div class="mediaplayer-small__open__content__controls__next" (click)="playNext()"
          *ngIf="!activeTune?.isPreview">
          <button mat-icon-button class="mediaplayer__progress__controls__next" aria-label="Play next tune"
            [disabled]="!hasNextTune">
            <mat-icon *ngIf="hasNextTune" inline="true">skip_next</mat-icon>
            <mat-icon *ngIf="!hasNextTune" style="color:grey" inline="true">skip_next</mat-icon>
          </button>
        </div>
      </div>

      <div class="mediaplayer-small__open__content__settings" *ngIf="!activeTune?.isPreview">
        <button mat-flat-button (click)="playTune('full')" *ngIf="activeTune?.type === 'snippet'"
          aria-label="Play full song">
          <mat-icon>play_arrow</mat-icon> Full song
        </button>
        <button mat-flat-button (click)="playTune('snippet')" *ngIf="activeTune?.type === 'full'"
          aria-label="Play snippet">
          <mat-icon>play_arrow</mat-icon> Snippet
        </button>
        <div class="mediaplayer-small__open__content__settings__autoplay"
          [matTooltip]="autoplayOn ? 'Autoplay is on' : 'Autoplay is off'">
          <span>Autoplay</span>
          <mat-slide-toggle
            [ngClass]="{'mediaplayer__actions__autoplay__toggle-on': autoplayOn, 'mediaplayer__actions__autoplay__toggle-off': !autoplayOn}"
            [checked]="autoplayOn" (change)="onToggleAutoplay($event)" color="primary"></mat-slide-toggle>
        </div>
      </div>

      <div class="mediaplayer-small__open__content__actions" *ngIf="!activeTune?.isPreview">

        <!-- Like -->
        <div *ngIf="uid !== activeTune?.author">
          <div class="mediaplayer-small__open__content__actions__action" *ngIf="!uid"
            (click)="openAuthRequired('like')">
            <button mat-icon-button aria-label="Like tune, but user is not logged in">
              <mat-icon>favorite_border</mat-icon>
            </button>
          </div>
          <div class="mediaplayer-small__open__content__actions__action" *ngIf="uid"
            [ngClass]="{'hide': activeTune?.liked}" (click)="likeTune()">
            <button mat-icon-button aria-label="Like tune">
              <mat-icon>favorite_border</mat-icon>
            </button>
          </div>
          <div class="mediaplayer-small__open__content__actions__action" *ngIf="uid"
            [ngClass]="{'hide': !activeTune?.liked}" (click)="dislikeTune()">
            <button mat-icon-button aria-label="Unlike tune">
              <mat-icon class="mediaplayer-small__open__content__actions__action__button__liked-icon">favorite
              </mat-icon>
            </button>
          </div>
        </div>
        <!-- End of like -->

        <div class="mediaplayer-small__open__content__actions__action">
          <button mat-icon-button aria-label="Go to comments" (click)="goTo('tune', activeTune?.key)">
            <i class="far fa-comment mediaplayer-small__open__content__actions__action__button__comment-icon"></i>
          </button>
        </div>

        <!-- Saved -->
        <div class="mediaplayer-small__open__content__actions__action" *ngIf="!uid" (click)="openAuthRequired('save')">
          <button mat-icon-button aria-label="Save tune, but user is not logged in">
            <mat-icon>bookmark_border</mat-icon>
          </button>
        </div>

        <div class="mediaplayer-small__open__content__actions__action" *ngIf="uid"
          [ngClass]="{'hide': activeTune?.saved}" (click)="saveTune()">
          <button mat-icon-button aria-label="Save tune">
            <mat-icon>bookmark_border</mat-icon>
          </button>
        </div>

        <div class="mediaplayer-small__open__content__actions__action" *ngIf="uid"
          [ngClass]="{'hide': !activeTune?.saved}" (click)="unsaveTune()">
          <button mat-icon-button aria-label="Remove tune from saved tunes">
            <mat-icon class="mediaplayer-small__open__content__actions__action__button__saved-icon">bookmark_added
            </mat-icon>
          </button>
        </div>
        <!-- End of saved -->

        <div class="mediaplayer-small__open__content__actions__action" (click)="openShareDialog()">
          <button mat-icon-button aria-label="Open share dialog">
            <mat-icon>share</mat-icon>
          </button>
        </div>

        <div class="mediaplayer-small__open__content__actions__action" (click)="openSearchExternalDialog()">
          <button mat-icon-button aria-label="Open search external dialog">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
      </div>


    </div>
  </div>
</div>

<div class="mediaplayer hidden-s" [@visibilityMediaplayer]="visibilityMediaplayer">
  <div class="mediaplayer__info">
    <div class="mediaplayer__info__image" (click)="goTo('user', activeTune?.name)">
      <img *ngIf="activeTune?.image" [src]="activeTune?.image" alt="Profile picture of tune poster" />
    </div>
    <div class="mediaplayer__info__copy">
      <div class="mediaplayer__info__copy__title" (click)="goTo('tune', activeTune?.key)">
        {{ activeTune?.uploadTitle }}
      </div>
      <div class="mediaplayer__info__copy__author" (click)="goTo('user', activeTune?.name)">
        {{ activeTune?.name }}<span *ngIf="activeTune?.blog" class="fas fa-crown faside icon-crown"></span>
      </div>
    </div>
  </div>
  <div class="mediaplayer__progress">
    <div class="mediaplayer__progress__controls">
      <button mat-icon-button class="mediaplayer__progress__controls__previous" [disabled]="!hasPreviousTune"
        (click)="playPrevious()" aria-label="Play previous tune">
        <mat-icon *ngIf="hasPreviousTune" inline="true">skip_previous</mat-icon>
        <mat-icon *ngIf="!hasPreviousTune" style="color:grey" inline="true">skip_previous</mat-icon>
      </button>

      <button mat-icon-button disableRipple="true" class="mediaplayer__progress__controls__playpause"
        (click)="playTune()" aria-label="Play or pause tune">
        <mat-icon inline="true" [ngClass]="{'hide': activeTune?.youtubeState !== 2}">play_circle</mat-icon>
        <mat-icon inline="true" [ngClass]="{'hide': activeTune?.youtubeState !== 1}">pause_circle</mat-icon>
        <mat-icon inline="true" [ngClass]="{'hide': activeTune?.youtubeState !== 0}">refresh</mat-icon>
        <mat-progress-spinner class="mediaplayer__progress__controls__playpause__spinner" mode="indeterminate"
          strokeWidth="3" diameter="32"
          *ngIf="activeTune?.youtubeState === -1 || activeTune?.youtubeState === 3 || activeTune?.youtubeState === 5"
          aria-label="Tune loading">
        </mat-progress-spinner>
      </button>

      <button mat-icon-button class="mediaplayer__progress__controls__next" [disabled]="!hasNextTune"
        (click)="playNext()" aria-label="Play next tune">
        <mat-icon *ngIf="hasNextTune" inline="true">skip_next</mat-icon>
        <mat-icon *ngIf="!hasNextTune" style="color:grey" inline="true">skip_next</mat-icon>
      </button>
    </div>
    <div class="mediaplayer__progress__bar">
      <div class="mediaplayer__progress__bar__current-time">
        {{activeTune?.currentTime}}
      </div>

      <div class="mediaplayer__progress__bar__slider">
        <mat-slider color="primary" min="1" max="100" step="1"
          *ngIf="activeTune?.progress || activeTune?.progress === 0" [(ngModel)]="activeTune.progress"
          (change)="onChangeSlider()" (input)="onInputChangeProgress($event)" aria-label="Progressbar of the tune">
        </mat-slider>
      </div>

      <div class="mediaplayer__progress__bar__duration">
        {{activeTune?.duration}}
      </div>
    </div>
  </div>
  <div class="mediaplayer__actions">

    <div *ngIf="uid !== activeTune?.author">
      <div class="mediaplayer__actions__like" *ngIf="!uid" (click)="openAuthRequired('like')">
        <button mat-icon-button aria-label="Like tune, but user is not logged in">
          <mat-icon class="mediaplayer__actions__like__icon">favorite_border</mat-icon>
        </button>
      </div>

      <div class="mediaplayer__actions__like" *ngIf="uid" [ngClass]="{'hide': activeTune?.liked}" (click)="likeTune()">
        <button mat-icon-button aria-label="Like tune">
          <mat-icon class="mediaplayer__actions__like__icon">favorite_border</mat-icon>
        </button>
      </div>

      <div class="mediaplayer__actions__like" *ngIf="uid" [ngClass]="{'hide': !activeTune?.liked}"
        (click)="dislikeTune()">
        <button mat-icon-button aria-label="Unlike tune">
          <mat-icon class="mediaplayer__actions__like__icon--liked">favorite</mat-icon>
        </button>
      </div>
    </div>

    <div class="mediaplayer__actions__volume" (mouseenter)="showVolume()" (mouseleave)="hideVolume()">
      <mat-slider class="mediaplayer__actions__volume__slider" *ngIf="showVolumeSlider" color="primary" min="0"
        max="100" step="1" vertical="true" [(ngModel)]="volumeValue" (input)="onInputChangeVolume($event)"
        aria-label="Volume slider">
      </mat-slider>
      <button mat-icon-button *ngIf="volumeOn" (click)="turnVolumeOff()" aria-label="Turn volume off">
        <mat-icon>volume_up</mat-icon>
      </button>
      <button mat-icon-button *ngIf="!volumeOn" (click)="turnVolumeOn()" aria-label="Turn volume on">
        <mat-icon>volume_off</mat-icon>
      </button>
    </div>

    <div class="mediaplayer__actions__autoplay" [matTooltip]="autoplayOn ? 'Autoplay is on' : 'Autoplay is off'">
      <mat-slide-toggle
        [ngClass]="{'mediaplayer__actions__autoplay__toggle-on': autoplayOn, 'mediaplayer__actions__autoplay__toggle-off': !autoplayOn}"
        [checked]="autoplayOn" (change)="onToggleAutoplay($event)" color="primary" aria-label="Toggle autoplay">
      </mat-slide-toggle>
    </div>

  </div>
</div>
