import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchArtists',
    pure: false
})

export class SearchArtistsPipe implements PipeTransform {
    transform(pipeData: any, pipeModifier: any): any[] {

        if (pipeModifier == "") {
            return pipeData;
        }

        const filteredData = pipeData.filter((item: any) => {
            return (
                item['name'].toLowerCase().includes(pipeModifier.toLowerCase())
                ||
                item['number'].toLowerCase().includes(pipeModifier.toLowerCase())
                ||
                item['fullname'].toLowerCase().includes(pipeModifier.toLowerCase())
            )
        })

        if (filteredData.length > 0) {
            return filteredData
        };

        return [{
            isEmpty: true
        }]
    }
}

