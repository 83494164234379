<div [ngClass]="{'hide': tunesListIsLoading}" *ngFor="let tune of tunesList | async | orderBy: sortBy ; trackBy: trackByFn ; let i = index">
    <app-tune [tune]="tune" [layout]="layout" [page]="page" [index]="i + 1" [listKey]="listKey" (deleteFromList)="deleteFromList($event)"></app-tune>
</div>

<div class="tunes-list__loading" *ngIf="tunesListIsLoading">
    <mat-progress-spinner color="primary" mode="indeterminate" strokeWidth="4" diameter="40"></mat-progress-spinner>
</div>

<div class="tunes-list__empty" *ngIf="tunesListIsEmpty">
    No tunes found
</div>