import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class UsersDataService {

  constructor(private db: AngularFireDatabase) {};

  getUsers = (path: string, batch: number, lastKey?: string, orderBy?: string) => {
    return this.db.list(path, ref => {
      return this.getQuery(ref, batch, lastKey, orderBy);
    }).valueChanges();
  }

  getQuery = (ref: firebase.database.Reference, batch: number, lastKey?: string, orderBy?: string) => {
    if (orderBy) {
      if (lastKey) {
        return ref.orderByChild(orderBy).limitToLast(batch).endAt(lastKey);
      } else {
        return ref.orderByChild(orderBy).limitToLast(batch);
      }
    } else {
      if (lastKey) {
        return ref.orderByKey().limitToLast(batch).endAt(lastKey);
      } else {
        return ref.orderByKey().limitToLast(batch);
      }
    }
  }

}
