<div class="navigation">

  <app-youtube-video></app-youtube-video>

  <mat-sidenav-container style="overflow: unset">
    <mat-sidenav #drawer *ngIf="showSidebar" class="navigation__sidenav"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="((isHandset$ | async) === false) && handsetLoaded" (openedChange)="drawerChanged($event)">
      <mat-toolbar class="navigation__sidenav__toolbar">
        <img routerLink="/" class="navigation__sidenav__logo" src="assets/images/logo_nav_white.svg" alt="Main logo" />
      </mat-toolbar>

      <mat-nav-list class="navigation__sidenav__list"
        [ngClass]="{'navigation__sidenav__list__down': mediaplayerVisible}">
        <a mat-list-item class="navigation__sidenav__list__item" routerLink="/" routerLinkActive="navigation__active"
          [routerLinkActiveOptions]="{ exact: true }" (click)="closeSidenav()">
          <mat-icon>home</mat-icon>
          Home
        </a>
        <a mat-list-item class="navigation__sidenav__list__item" *ngIf="uid" routerLink="/discover"
          routerLinkActive="navigation__active" (click)="closeSidenav()">
          <mat-icon>language</mat-icon>
          Discover
        </a>
        <a mat-list-item class="navigation__sidenav__list__item" routerLink="/popular"
          routerLinkActive="navigation__active" (click)="closeSidenav()">
          <mat-icon>whatshot</mat-icon>
          Popular
        </a>
        <a mat-list-item class="navigation__sidenav__list__item" routerLink="/inspirational"
          routerLinkActive="navigation__active" (click)="closeSidenav()">
          <span style="margin-left: 3px" class="fas fa-crown navigation__sidenav__list__item__icon"></span>
          Inspirational
        </a>
        <!-- *ngIf="uid === 'bd350358-fc6c-4738-ac91-61a606bd6d01' || uid === 'db5b2783-b244-4493-9380-e5f34badfc15'" -->
        <a mat-list-item class="navigation__sidenav__list__item" routerLink="/blog"
          routerLinkActive="navigation__active" (click)="closeSidenav()">
          <mat-icon>subject</mat-icon>
          Blog
        </a>
        <a mat-list-item class="navigation__sidenav__list__item" *ngIf="uid" routerLink="/profile/{{user?.username}}"
          routerLinkActive="navigation__active" (click)="closeSidenav()" [class.listItemDisabled]="!user">
          <mat-icon>person</mat-icon>
          My profile
        </a>
        <a mat-list-item class="navigation__sidenav__list__item" *ngIf="uid" routerLink="/saved"
          routerLinkActive="navigation__active" (click)="closeSidenav()">
          <mat-icon>bookmark</mat-icon>
          Saved
        </a>
        <a mat-list-item class="navigation__sidenav__list__item" *ngIf="uid" routerLink="/liked-tunes"
          routerLinkActive="navigation__active" (click)="closeSidenav()">
          <mat-icon>favorite</mat-icon>
          Liked tunes
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content style="overflow: unset">
      <mat-toolbar>
        <button type="button" *ngIf="showSidebar && (isHandset$ | async)" mat-icon-button (click)="toggleDrawer()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>

        <div class="navigation__toolbar">

          <div class="navigation__toolbar__auth-logo" *ngIf="currentRoute.startsWith('/auth')" routerLink="/">
            <img src="assets/images/logo_nav_white.svg" alt="Main logo" />
          </div>

          <div class="navigation__toolbar__search" *ngIf="!currentRoute.startsWith('/auth')">
            <app-navigation-search></app-navigation-search>
          </div>

          <div class="navigation__toolbar__post hidden-s" *ngIf="!currentRoute.startsWith('/auth')">
            <button class="navigation__toolbar__post__button" *ngIf="!uid" aria-label="Post tune" mat-raised-button
              color="primary" (click)="openAuthRequired('post')">
              <span>POST</span>
            </button>
            <button class="navigation__toolbar__post__button" *ngIf="uid" aria-label="Post tune" mat-raised-button
              color="primary" (click)="goTo('post')">
              <span>POST</span>
            </button>
          </div>

          <div class="navigation__toolbar__post visible-s" *ngIf="!currentRoute.startsWith('/auth')">
            <button mat-mini-fab color="primary" aria-label="Post tune" *ngIf="!uid" (click)="openAuthRequired('post')">
              <mat-icon>add</mat-icon>
            </button>
            <button mat-mini-fab color="primary" aria-label="Post tune" *ngIf="uid" (click)="goTo('post')">
              <mat-icon>add</mat-icon>
            </button>
          </div>

          <div class="navigation__toolbar__right" *ngIf="!uid">
            <div class="navigation__toolbar__right__not-auth" *ngIf="currentRoute.startsWith('/auth/login')">
              <button mat-stroked-button color="primary" aria-label="Create account"
                routerLink="/auth/register"><b>Create account</b></button>
            </div>
            <div class="navigation__toolbar__right__not-auth" *ngIf="currentRoute.startsWith('/auth/register')">
              <button mat-stroked-button color="primary" aria-label="Log in" routerLink="/auth/login"><b>Log
                  in</b></button>
            </div>
            <div class="navigation__toolbar__right__not-auth visible-s" *ngIf="!currentRoute.startsWith('/auth')">
              <button mat-stroked-button color="primary" aria-label="Log in or create account"
                routerLink="/auth/register"><b>Log in / Register</b></button>
            </div>
            <div class="navigation__toolbar__right__not-auth hidden-s" *ngIf="!currentRoute.startsWith('/auth')">
              <button mat-stroked-button color="primary" aria-label="Log in" routerLink="/auth/login"><b>Log
                  in</b></button>
              <div class="navigation__toolbar__right__not-auth__or">or</div>
              <button mat-raised-button color="primary" aria-label="Create account" routerLink="/auth/register">Create
                account</button>
            </div>
          </div>

          <div class="navigation__toolbar__right" *ngIf="uid">
            <div class="navigation__toolbar__right__new-tuners">
              <app-navigation-new-tuners></app-navigation-new-tuners>
            </div>

            <div class="navigation__toolbar__right__notifications">
              <app-navigation-notifications></app-navigation-notifications>
            </div>

            <div class="navigation__toolbar__right__profile">
              <app-navigation-profile></app-navigation-profile>
            </div>
          </div>

        </div>
        <!--
        <mat-slide-toggle (click)="toggleDarkMode()" [(ngModel)]="isDarkMode">
          Dark theme
        </mat-slide-toggle> -->


      </mat-toolbar>

      <div class="navigation__content">
        <div class="navigation__content__main">
          <router-outlet></router-outlet>
        </div>
        <div class="navigation__content__side" *ngIf="showSidebar">
          <div class="navigation__content__side__inner">
            <app-sidebar></app-sidebar>
          </div>
        </div>
      </div>

    </mat-sidenav-content>

  </mat-sidenav-container>

  <app-mediaplayer></app-mediaplayer>

  <div class="navigation__tabs visible-s">
    <div class="navigation__tabs__tab" (click)="minimizeMediaplayer()"
      [ngClass]="{'navigation__tabs__tab--no-auth': !uid}" matRipple routerLink="/">
      <mat-icon [ngStyle]="{'color': currentRoute === '/' ? '#8377ff' : 'grey'}">home</mat-icon>
    </div>
    <div class="navigation__tabs__tab" (click)="minimizeMediaplayer()" matRipple routerLink="/discover" *ngIf="uid">
      <mat-icon [ngStyle]="{'color': currentRoute.startsWith('/discover') ? '#8377ff' : 'grey'}">language</mat-icon>
    </div>
    <div class="navigation__tabs__tab" (click)="minimizeMediaplayer()"
      [ngClass]="{'navigation__tabs__tab--no-auth': !uid}" *ngIf="!uid" matRipple routerLink="/popular">
      <mat-icon [ngStyle]="{'color': currentRoute.startsWith('/popular') ? '#8377ff' : 'grey'}">whatshot</mat-icon>
    </div>
    <div class="navigation__tabs__tab" (click)="minimizeMediaplayer()"
      [ngClass]="{'navigation__tabs__tab--no-auth': !uid}" matRipple routerLink="/inspirational">
      <mat-icon [ngStyle]="{'color': currentRoute.startsWith('/inspirational') ? '#8377ff' : 'grey'}">star</mat-icon>
    </div>
    <!-- <div class="navigation__tabs__tab" *ngIf="!user">
      <mat-icon>person</mat-icon>
    </div> -->
    <div class="navigation__tabs__tab" (click)="minimizeMediaplayer()" *ngIf="user" matRipple
      routerLink="/profile/{{user?.username}}">
      <mat-icon [ngStyle]="{'color': isOnOwnProfile ? '#8377ff' : 'grey'}">person</mat-icon>
    </div>
  </div>

</div>
