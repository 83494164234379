<div class="use-app">
  <div class="use-app__android">
    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.tunrmusic.tunr">
      <img src="assets/images/google-play-icon.svg" />
    </a>
  </div>
  <div class="use-app__ios">
    <a target="_blank" href="https://itunes.apple.com/app/id1438640200">
      <img style="margin-top:-2px" src="assets/images/app-store-icon.svg" />
    </a>
  </div>
</div>
