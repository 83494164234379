<div class="dialog-repost-tune">
    <h1 mat-dialog-title>Repost tune</h1>

    <div class="dialog-repost-tune__header">
        {{tune?.uploadTitle}}
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput cdkTextareaAutosize spellcheck="false" autocomplete="off" type="text" maxlength="400"
        [(ngModel)]="repostDescription"></textarea>
    </mat-form-field>
  
    <div mat-dialog-actions align="end">
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-flat-button color="primary" (click)="repost()" cdkFocusInitial [disabled]="loading"
        class="dialog-repost-tune__save-button">
        <span *ngIf="!loading">Repost tune</span>
        <mat-spinner class="dialog-repost-tune__save-button__spinner" *ngIf="loading" diameter="25"></mat-spinner>
      </button>
    </div>
  
  </div>
  