import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { take } from 'rxjs/operators';
import { Tune } from 'src/app/features/shared/interfaces/tune';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EditTuneService {

  uid!:string | null;

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService
  ) {
    this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    })
  }

  editTitle(title: string, key: string) {
    this.db.object(`/tunes/${key}`).update({ uploadTitle: title });
    this.db.object(`/topTunes/${key}`).update({ uploadTitle: title });
    this.db.object(`/randomTunes/${key}`).update({ uploadTitle: title });

    this.editHashtagSearch(key);
  }

  editDescription(description: string, key: string) {
    this.db.object(`/tunes/${key}`).update({ reasonUpload: description });
    this.db.object(`/topTunes/${key}`).update({ reasonUpload: description });
    this.db.object(`/randomTunes/${key}`).update({ reasonUpload: description });

    this.editHashtagSearch(key);
  }
  
  editRepostDescription(description: string = "", key: string) {
   this.db.object(`/tunes/${key}`).update({ repostDescription: description });
  }

  addHashtags = async (hashtags: Object[], key: string) => {
    let promises: any[] = [];

    this.db.list(`/hashtagsByTune/${key}`).valueChanges().pipe(take(1)).subscribe(hashtags => {
      hashtags.forEach((hashtag: any) => {
        // Remove the key from the hashtags list
        const promise = this.db.object(`/hashtags/${hashtag.lowercaseHashtag}/${key}`).remove();
        promises.push(promise);

        // Decrease the hashtagsCount by 1
        this.db.object(`/hashtagsCount/${hashtag.lowercaseHashtag}/hashtagsCount`).query.ref.transaction(count => count ? --count : 0);
      })
    })

    await Promise.all(promises);
    await this.db.object(`/hashtagsByTune/${key}`).remove();

    hashtags.forEach((hashtag: any) => {
      const originalHashtag = hashtag.originalHashtag;
      const cleanHashtag = hashtag.cleanHashtag;
      const lowercaseHashtag = hashtag.lowercaseHashtag;

      const hashtagObject = {
        originalHashtag,
        cleanHashtag,
        lowercaseHashtag
      }

      // Push to list of tuneKeys per hashtag
      this.db.object(`/hashtags/${lowercaseHashtag}/${key}`).set({key})

      // Push to the hashtags by tune
      this.db.object(`/hashtagsByTune/${key}/${lowercaseHashtag}`).set(hashtagObject);

      // Increment the hashtagsCount by 1
      this.db.object(`/hashtagsCount/${lowercaseHashtag}/hashtagsCount`).query.ref.transaction(count => count ? ++count : 1);
    })

    this.editHashtagSearch(key);
  }

  editHashtagSearch = (key: string) => {
    setTimeout(() => {
      this.db.object(`/tunes/${key}`).valueChanges().pipe(take(1)).subscribe((tune: Tune | any) => {
        const uploadTitle = tune.uploadTitle;
        const reasonUpload = (tune.reasonUpload && tune.reasonUpload !== '') ? tune.reasonUpload : '';
        const combinedString = uploadTitle + ' ' + reasonUpload;

        this.db.list(`/hashtagsByTune/${key}`).valueChanges().pipe(take(1)).subscribe((hashtags: any[]) => {
          hashtags.forEach((hashtag: any) => {
            const cleanHashtag = hashtag.cleanHashtag;
            this.db.object(`/hashtags/${cleanHashtag}/${key}/s`).set(combinedString);
          })
        })
      })
    }, 3000);
  }

  deleteTune = async (key:string) => {

    const isAuthor = await this.db.object(`/uploads/${this.uid}/${key}`).valueChanges().pipe(take(1)).toPromise().then(value => value ? true : false);
    if (!isAuthor) return;

    this.db.object(`/uploads/${this.uid}/${key}`).remove();
    this.db.object(`/feeds/${this.uid}/${key}`).remove();
    this.db.object(`/likesTotal/${key}`).remove();
    this.db.object(`/tunes/${key}`).remove();
    this.db.object(`/topTunes/${key}`).remove();
    this.db.object(`/randomTunes/${key}`).remove();

    let promises: any[] = [];

    this.db.list(`/hashtagsByTune/${key}`).valueChanges().pipe(take(1)).subscribe(hashtags => {
      hashtags.forEach((hashtag: any) => {
        // Remove the key from the hashtags list
        const promise = this.db.object(`/hashtags/${hashtag.lowercaseHashtag}/${key}`).remove();
        promises.push(promise);

        // Decrease the hashtagsCount by 1
        this.db.object(`/hashtagsCount/${hashtag.lowercaseHashtag}/hashtagsCount`).query.ref.transaction(count => count ? --count : 0);
      })
    })

    await Promise.all(promises);
    await this.db.object(`/hashtagsByTune/${key}`).remove();

    // Remove from totalTunesCount
    this.db.object(`/totalTunesCount/totalTunesCount`).query.ref.transaction(count => count ? --count : null);

    // Remove from uploadsCount
    this.db.object(`/uploadsCount/${this.uid}/uploadsCount`).query.ref.transaction(count => count ? --count : 0);

    // Remove from the feed of your followers
    this.db.list(`/followers/${this.uid}`).valueChanges().pipe(take(1)).subscribe(followers => {
      followers.forEach((follower:any) => {
        this.db.object(`/feeds/${follower.regUser}/${key}`).remove();
      })
    })
  }

  deleteRepostTune = async (tune: Tune, originalKey?: string) => {
    // If the tune has no originalKey from itself, but is from a reposted tune, overwrite the originalKey
    // For example, if the user deletes his repost by pressing the active repost button on someone else's tune
    if (originalKey) tune.originalKey = originalKey;

    this.db.object(`/uploads/${this.uid}/${tune.originalKey}`).remove();
    this.db.object(`/feeds/${this.uid}/${tune.originalKey}`).remove();
    this.db.object(`/tunes/${tune.originalKey}`).remove();

    // Decrement the uploadsCount by 1
    this.db.object(`/uploadsCount/${this.uid}/uploadsCount`).query.ref.transaction(count => count ? --count : 0);

    // Decrement your repostedTunesCount by 1
    this.db.object(`/repostedTunesCount/${this.uid}/repostedTunesCount`).query.ref.transaction(count => count ? --count : 0);

    // Remove tune from your reposted tunes list
    this.db.object(`/repostedTunes/${this.uid}/${tune.key}`).set(null);

    // Decrement the repostCount of the tune by 1
    this.db.object(`/repostCount/${tune.key}/repostCount`).query.ref.transaction(count => count ? --count : 0);

    // Remove from the feed of your followers
    this.db.list(`/followers/${this.uid}`).valueChanges().pipe(take(1)).subscribe(followers => {
      followers.forEach((follower:any) => {
        this.db.object(`/feeds/${follower.regUser}/${tune.originalKey}`).remove();
      })
    })

    // Removes the main data of the notification
    this.db.list(`/notifications/${tune.author}/${tune.key}repost/users`).valueChanges().pipe(take(1)).subscribe(userList => {
      // If the last user is removed, the entire notification gets deleted
      if (userList.length == 1) {
        this.db.object(`/notifications/${tune.author}/${tune.key}repost`).set(null);
      }
      this.db.object(`/notifications/${tune.author}/${tune.key}repost/users/${this.uid}`).set(null);
    })

    // Removes the currentUser from the amount of new notifications
    this.db.object(`/notificationsCount/${tune.author}/${tune.key}replies`).set(null);
  }

  editPrice = (buyAmount: number, selectedCurrency: string, key: string) => {
    this.db.object(`/tunes/${key}`).update({
      buyAmount,
      selectedCurrency
    })
    this.db.object(`/topTunes/${key}`).update({
      buyAmount,
      selectedCurrency
    })
    this.db.object(`/randomTunes/${key}`).update({
      buyAmount,
      selectedCurrency
    })
  }

  editLink = (link: string, key: string) => {
    this.db.object(`/tunes/${key}`).update({buyNowLink: link});
    this.db.object(`/topTunes/${key}`).update({buyNowLink: link});
    this.db.object(`/randomTunes/${key}`).update({buyNowLink: link});
  }

  editBuyComplete = (isComplete: boolean, key: string) => {
    this.db.object(`/tunes/${key}`).update({buyComplete: isComplete});
    this.db.object(`/topTunes/${key}`).update({buyComplete: isComplete});
    this.db.object(`/randomTunes/${key}`).update({buyComplete: isComplete});
  }

  deleteBuyInfo = (key: string) => {
    this.db.object(`/tunes/${key}`).update({
      buyNowLink: '',
      buyAmount: '',
      buyComplete: false
    });
    this.db.object(`/topTunes/${key}`).update({
      buyNowLink: '',
      buyAmount: '',
      buyComplete: false
    });
    this.db.object(`/randomTunes/${key}`).update({
      buyNowLink: '',
      buyAmount: '',
      buyComplete: false
    });
  }
}
