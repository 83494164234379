import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-sign-in-methods',
  templateUrl: './dialog-sign-in-methods.component.html',
  styleUrls: ['./dialog-sign-in-methods.component.scss']
})
export class DialogSignInMethodsComponent implements OnInit {
  methods: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) { 
    if (data && data.methods) this.methods = this.mapMethods(data.methods); 
  }

  mapMethods = (methods: string[]) => {
    return methods.map((method: string) => {
      if (method === 'facebook.com') {
        return 'Facebook';
      } 
      else if (method === 'apple.com') {
        return 'Apple';
      }
      else {
        return 'Email and password';
      }
    })
  }

  ngOnInit(): void {
  }

}
