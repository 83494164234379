import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './core/services/auth/auth.service';
declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
    private authService: AuthService
  ) {

    // BROWSER
    if (isPlatformBrowser(this.platformId)) {

      // Check if the logged in user has a username. If not, send to the finish registration page
      this.authService.user$.subscribe(user => {
        if (user && !user.username) {
          this.router.navigate(['/auth/finish-registration']);
        }
      })

      // The script for gtag
      const script = this.renderer.createElement('script') as HTMLScriptElement;
      script.src = `https://www.googletagmanager.com/gtag/js?id=UA-78372239-1`;
      script.async = true;
      this.renderer.appendChild(this.el.nativeElement, script);

      const script2 = this.renderer.createElement('script') as HTMLScriptElement;
      const scriptBody = this.renderer.createText(`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', 'UA-78372239-1'); 
        gtag('config', 'AW-729127110');
      `);
      this.renderer.appendChild(script2, scriptBody);
      this.renderer.appendChild(this.el.nativeElement, script2);

      // On route change
      router.events.subscribe((y: NavigationEnd | any) => {
        if (y instanceof NavigationEnd) {
          gtag('config', 'UA-78372239-1', { 'page_path': y.url }); // Analytics
          fbq('track', 'PageView');

          if (y.url === '/auth/register') {
            gtag('event', 'conversion', {'send_to': 'AW-729127110/zgwRCIWZ3YoDEMax1tsC'}); // Register page Google Ads
          }
        }
      })
    }
  }
}
