<div class="dialog-edit-repost-description">
  <h1 mat-dialog-title>Edit repost description</h1>

  <div class="dialog-edit-repost-description__content">

    <mat-form-field>
      <mat-label>Repost description</mat-label>
      <textarea matInput cdkTextareaAutosize spellcheck="false" autocomplete="off" type="text" maxlength="400"
        [(ngModel)]="repostDescription"></textarea>
    </mat-form-field>

  </div>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-flat-button color="primary" (click)="save()" cdkFocusInitial [disabled]="loading"
      class="dialog-edit-repost-description__save-button">
      <span *ngIf="!loading">Save</span>
      <mat-spinner class="dialog-edit-repost-description__save-button__spinner" *ngIf="loading" diameter="25">
      </mat-spinner>
    </button>
  </div>

</div>
