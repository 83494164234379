import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommentService } from 'src/app/core/services/comment/comment.service';
import { SubSink } from 'subsink';
import { DeleteCommentComponent } from '../../dialogs/delete-comment/delete-comment.component';
import { ReportComponent } from '../../dialogs/report/report.component';
import { Comment } from '../../interfaces/comment';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  private subs = new SubSink();

  @Output('openReplyToComment') openReplyToComment: EventEmitter<any> = new EventEmitter();

  @Input() tuneKey!: string | undefined;
  @Input() comment!: Comment;
  @Input() hideReply: boolean = false;

  uid!: string | null;

  constructor(
    private dialog: MatDialog,
    private commentService: CommentService,
    private authService: AuthService
  ) {
    this.subs.add(this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openReply = () => {
    this.openReplyToComment.emit(this.comment);
  }

  reportComment = () => {
    const dialogRef = this.dialog.open(ReportComponent, {
      data: {
        tuneKey: this.tuneKey,
        comment: this.comment
      },
      width: '90%',
      maxWidth: 400
    });

    dialogRef.afterClosed().subscribe(result => {
      const data: any = result[0];
      const tuneKey = data.tuneKey;
      const comment = data.comment;
      const reportObject: any = result[1];
      let reportValues: string[] = [];

      if (reportObject) {
        Object.keys(reportObject).forEach(key => {
          if (reportObject[key]) reportValues.push(key);
        })
      }

      if (data && data.comment && tuneKey && reportValues.length > 0) {
        this.commentService.reportComment(reportValues, comment, tuneKey);
      }
    })
  }

  deleteComment = () => {
    const dialogRef = this.dialog.open(DeleteCommentComponent, {
      data: {
        tuneKey: this.tuneKey,
        comment: this.comment
      },
      width: '90%',
      maxWidth: 400
    });

    dialogRef.afterClosed().subscribe(result => {
      const tuneKey = result.tuneKey;
      const comment = result.comment;

      if (tuneKey && comment) {
        this.commentService.deleteComment(tuneKey, comment);
      }
    })
  }

  goTo = (path: any, id: any) => {

  }

  goToReply = () => {

  }
}
