<div class="user" matRipple (click)="goTo()">
    <div class="user__image">
        <img [src]="user?.image" />
    </div>
    <div class="user__names">
        <div class="user__names__username">
            {{user?.username}}
        </div>
        <div class="user__names__fullname">
            {{user?.firstname}} {{user?.lastname}}
        </div>
    </div>
    <div class="user__tune-in">
        <app-tune-in-button [size]="'small'" (click)="stopRouterLink($event)" [username]="user?.username ? user?.username : user?.name" [userUid]="user?.author ? user?.author : user?.regUser"></app-tune-in-button>
    </div>
</div>