import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root',
})
export class InspirationalService {
  constructor(private db: AngularFireDatabase) {}

  getInspirationalArtists = (
    batch: number,
    lastNumber?: number,
    isTunrBlog?: boolean
  ) => {
    if (isTunrBlog) {
      return this.db.list(`/blogsShort`, (ref) => {
        if (lastNumber) {
          return ref
            .orderByChild('tunrBlog')
            .startAt(true)
            .limitToLast(batch)
            .endAt(lastNumber);
        } else {
          return ref.orderByChild('tunrBlog').startAt(true).limitToLast(batch);
        }
      });
    } else {
      return this.db.list(`/blogsShort`, (ref) => {
        if (lastNumber) {
          return ref
            .orderByChild('number')
            .limitToLast(batch)
            .endAt(lastNumber);
        } else {
          return ref.orderByChild('number').limitToLast(batch);
        }
      });
    }
  };
}
