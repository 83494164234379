import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditTuneService } from 'src/app/core/services/edit-tune/edit-tune.service';
import { Tune } from '../../interfaces/tune';

@Component({
  selector: 'app-edit-repost-description',
  templateUrl: './edit-repost-description.component.html',
  styleUrls: ['./edit-repost-description.component.scss']
})
export class EditRepostDescriptionComponent implements OnInit {
  tune!: Tune;
  loading: boolean = false;
  repostDescription!: string;

  constructor(
    private dialogRef: MatDialogRef<EditRepostDescriptionComponent>,
    private editTuneService: EditTuneService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.tune = {...data.tune};

    if (this.tune.repostDescription) {
      this.repostDescription = this.tune.repostDescription;
    }
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save = () => {
    this.loading = true;

    if (!this.tune.key || !this.tune.uploadTitle) {
      this.loading = false;
      return;
    };

    const repostDescription = this.repostDescription ? this.repostDescription : "";
    
    if (this.tune.originalKey) this.editTuneService.editRepostDescription(repostDescription, this.tune.originalKey);

    setTimeout(() => {
      this.dialogRef.close();
    }, 400);
  }
}
