<div class="dialog-delete-comment">
    <h1 mat-dialog-title>Delete comment?</h1>
    
      <div class="dialog-delete-comment__warning">
        Are you sure you want to delete this comment?
      </div>
    
      <div mat-dialog-actions align="end">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-flat-button color="danger" [mat-dialog-close]="data" cdkFocusInitial
          class="dialog-delete-comment__delete-button">
          <span>Remove</span>
        </button>
      </div>
</div>
