import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable, combineLatest, of } from 'rxjs';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { switchMap, map, take } from 'rxjs/operators';
import { User } from 'src/app/features/shared/interfaces/user';
import { Tune } from 'src/app/features/shared/interfaces/tune';
import firebase from 'firebase/app';
import { Comment } from 'src/app/features/shared/interfaces/comment';

@Injectable({
    providedIn: 'root'
})
export class TunesDataService {
    uid!: string | null;
    user!: User | null;

    constructor(
        private authService: AuthService,
        private db: AngularFireDatabase
    ) {
        this.uid = this.authService.getUid();

        this.authService.uid$.subscribe(uid => {
            this.uid = uid;
        })
        this.authService.user$.subscribe(user => {
            this.user = user;
        })
    }

    getTunes = (path: string, batch: number, lastKey?: string, orderBy?: string): Observable<any> => {
        const tunesRef: AngularFireList<Tune> = this.db.list(path, ref => {
            return this.getQuery(ref, batch, lastKey, orderBy);
        })

        if (orderBy) {
            return tunesRef.valueChanges()
        } else {
            return tunesRef.valueChanges().pipe(
                map(results => results.map(result => result.key))
            )
        }
    }

    getTuneData = (key: string) => {
        if (!key) return of({});

        return this.db.object(`/tunes/${key}`).valueChanges().pipe(
            map((tune: Tune | any) => {
                if (tune) {
                    const tuneKey = tune.isRepost ? tune.repostTuneKey : tune.key;
                    let tuneObj: any = { tuneKey };
                    if (tune.isRepost) tuneObj = { ...tuneObj, ...tune, originalKey: tune.originalKey };
                    return tuneObj;
                } else {
                    return of(null);
                }
            }),
            switchMap((tuneObj: any) => {
                const tune$ = this.db.object(`/tunes/${tuneObj.tuneKey}`).valueChanges();
                const liked$ = this.db.object(`/liked/${this.uid}/${tuneObj.tuneKey}`).valueChanges();
                const saved$ = this.db.object(`/addedtowaitlist/${this.uid}/${tuneObj.tuneKey}`).valueChanges();
                const reposted$ = this.db.object(`/repostedTunes/${this.uid}/${tuneObj.tuneKey}`).valueChanges();
                const didnotlisten$ = this.db.object(`/didnotlisten/${this.uid}/${tuneObj.tuneKey}`).valueChanges();
                const hashtags$ = this.db.list(`/hashtagsByTune/${tuneObj.tuneKey}`).valueChanges();
                const likesTotal$ = this.db.list(`/likesTotal/${tuneObj.tuneKey}`).valueChanges();
                const commentsCount$ = this.db.object(`/commentsCount/${tuneObj.tuneKey}`).valueChanges();
                const comments$ = this.db.list(`/comments/${tuneObj.tuneKey}`).snapshotChanges().pipe(map((comments: any[]) => {
                    return comments.map((c: any) => ({ commentKey: c.payload.key, ...c.payload.val() }));
                }))

                return combineLatest([tune$, liked$, saved$, reposted$, didnotlisten$, hashtags$, likesTotal$, commentsCount$, comments$]).pipe(
                    map(([tune, liked, saved, reposted, didnotlisten, hashtags, likesTotal, commentsCount, comments]: any[]) => {

                        delete tuneObj.tuneKey, delete tuneObj.repostTuneKey;

                        // Overwrite the date if it is a repost
                        if (tune && tuneObj.repostDate) {
                            tune.originalTuneDate = tune.date;
                            tune.date = tuneObj.repostDate;
                        }

                        return {
                            ...tuneObj,
                            ...tune,
                            liked: liked ? true : false,
                            saved: saved ? true : false,
                            reposted: reposted ? true : false,
                            didnotlisten: didnotlisten ? true : false,
                            hashtags: hashtags ? hashtags : [],
                            likesCount: likesTotal ? likesTotal.length : 0,
                            comments: comments ? comments : [],
                            commentsCount: commentsCount ? commentsCount.commentsCount : 0
                        }
                    }),
                    switchMap(combinedObj => {
                        const author = combinedObj.author;
                        const image$ = this.db.object('/images/' + author + '/image').valueChanges();
                        let commentAuthors$: Observable<any> = of(null);

                        if (combinedObj.comments.length > 0) {
                            let commentAuthorImages$: any[] = [];

                            combinedObj.comments.forEach((comment: Comment) => {
                                commentAuthorImages$.push(this.db.object(`/images/${comment.author}/image`).valueChanges());

                                // Push replies as well
                                if (comment.repliesCount && comment.repliesCount > 0) {
                                    const replies$ = this.db.list(`/replies/${comment.commentKey}`, ref =>
                                        ref.orderByKey()).snapshotChanges().pipe(take(1), map((replies: any[]) => {
                                            return replies.map((c: any) => ({ replyKey: c.payload.key, ...c.payload.val() }));
                                        }));

                                    replies$.subscribe((replies: any[]) => {
                                        if (replies.length > 0) {
                                            replies.forEach((reply: Comment) => {
                                                this.db.object(`/images/${reply?.author}/image`).valueChanges().pipe(take(1)).subscribe((image: any) => {
                                                    reply.image = image;
                                                })
                                            })
                                        }

                                        comment.replies = replies;
                                    })
                                }
                            })

                            commentAuthors$ = combineLatest([...commentAuthorImages$]);
                        }

                        return combineLatest([of(combinedObj), image$, commentAuthors$]).pipe(
                            map(([combinedObj, image, commentAuthors]: any[]) => {

                                if (commentAuthors) {
                                    combinedObj.comments.forEach((comment: any, index: any) => {
                                        comment.image = commentAuthors[index];
                                    })
                                }

                                if (combinedObj.blog) {
                                    return {
                                        ...combinedObj,
                                        comments: combinedObj.comments
                                    }
                                } else {
                                    return {
                                        ...combinedObj,
                                        image,
                                        comments: combinedObj.comments
                                    }
                                }

                            })
                        )
                    })
                );
            })
        )
    }

    getQuery = (ref: firebase.database.Reference, batch: number, lastKey?: string, orderBy?: string) => {
        if (orderBy) {
            if (lastKey) {
                return ref.orderByChild(orderBy).limitToLast(batch).endAt(lastKey);
            } else {
                return ref.orderByChild(orderBy).limitToLast(batch);
            }
        } else {
            if (lastKey) {
                return ref.orderByKey().limitToLast(batch).endAt(lastKey);
            } else {
                return ref.orderByKey().limitToLast(batch);
            }
        }
    }
}
