import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Tune } from '../../interfaces/tune';
import { MatChipInputEvent } from '@angular/material/chips';
import { EditTuneService } from 'src/app/core/services/edit-tune/edit-tune.service';

@Component({
  selector: 'app-edit-tune',
  templateUrl: './edit-tune.component.html',
  styleUrls: ['./edit-tune.component.scss']
})
export class EditTuneComponent implements OnInit {
  tune!: Tune;
  hashtags: any[] = [];
  hashtagsDisabled!: boolean;
  loading: boolean = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dialogRef: MatDialogRef<EditTuneComponent>,
    private ch: ChangeDetectorRef,
    private editTuneService: EditTuneService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.tune = {...data.tune};

    if (this.tune.hashtags) {
      this.hashtags = this.tune.hashtags.slice();
      this.checkHashtagCount();
    }
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save = () => {
    this.loading = true;

    if (!this.tune.key || !this.tune.uploadTitle) {
      this.loading = false;
      return;
    };

    const reasonUpload = this.tune.reasonUpload ? this.tune.reasonUpload : "";

    this.editTuneService.editTitle(this.tune.uploadTitle, this.tune.key);
    this.editTuneService.editDescription(reasonUpload, this.tune.key);
    this.editTuneService.addHashtags(this.hashtags, this.tune.key);

    setTimeout(() => {
      this.dialogRef.close();
    }, 400);
  }

  addHashtag = (event: MatChipInputEvent): void => {
    const input = event.input;
    let value = event.value;
    const hasMultipleHashtags = value.match(/([#])\w+/g);

    if (hasMultipleHashtags) {
      value = hasMultipleHashtags[0];
    }

    if (!this.hashtagsDisabled) {
      if ((value || '').trim()) {
        if (value[0] == '#') {
          this.hashtags.push({
            originalHashtag: value.trim().replace(/\s+/g, ''),
            cleanHashtag: value.trim().substring(1).replace(/\s+/g, ''),
            lowercaseHashtag: value.trim().substring(1).toLowerCase().replace(/\s+/g, '')
          });
        } else {
          this.hashtags.push({
            originalHashtag: '#' + value.trim().replace(/\s+/g, ''),
            cleanHashtag: value.trim().replace(/\s+/g, ''),
            lowercaseHashtag: value.trim().toLowerCase().replace(/\s+/g, '')
          });
        }
      }
    }

    this.checkHashtagCount();

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeHashtag(hashtag: any): void {
    const index = this.hashtags.indexOf(hashtag);

    if (index >= 0) {
      this.hashtags.splice(index, 1);
    }
    this.checkHashtagCount();
  }

  checkHashtagCount = () => {
    if (this.hashtags.length > 9) {
      this.hashtagsDisabled = true;
    } else {
      this.hashtagsDisabled = false;
    }
    //this.ch.detectChanges();
  }
}
