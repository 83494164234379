import { NgModule, SkipSelf, Optional } from "@angular/core";

// Modules
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { LayoutModule } from '@angular/cdk/layout';
import { MaterialModule } from "../material.module";
import { RouterModule } from "@angular/router";
import { TimeagoModule } from "ngx-timeago";
import { SharedModule } from "../features/shared/shared.module";

// Components
import { NavigationComponent } from "./components/navigation/navigation.component";
import { MediaplayerComponent } from "./components/mediaplayer/mediaplayer.component";
import { SidebarComponent } from './components/navigation/sidebar/sidebar.component';

// Guards
import { EnsureModuleLoadedOnceGuard } from './guards/ensure-module-loaded-once.guard';
import { YoutubeVideoComponent } from './components/youtube-video/youtube-video.component';
import { SearchComponent } from './components/navigation/search/search.component';
import { NewTunersComponent } from './components/navigation/new-tuners/new-tuners.component';
import { NotificationsComponent } from './components/navigation/notifications/notifications.component';
import { PipesModule } from "./pipes/pipes.module";
import { ProfileComponent } from './components/navigation/profile/profile.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";

const CoreComponents = [NavigationComponent, MediaplayerComponent, YoutubeVideoComponent, SearchComponent, NewTunersComponent, NotificationsComponent, ProfileComponent, SidebarComponent];

@NgModule({
    imports: [
        CommonModule, 
        MaterialModule, 
        FormsModule,
        LayoutModule,
        RouterModule,
        TimeagoModule,
        SharedModule,
        PipesModule,
        InfiniteScrollModule
    ],
    declarations: [...CoreComponents],
    exports: [...CoreComponents]
})

export class CoreModule extends EnsureModuleLoadedOnceGuard {
    // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        super(parentModule);
    }
}