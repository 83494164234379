import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { YoutubeVideoService } from 'src/app/core/services/youtube-video/youtube-video.service';
import { TuneActionsService } from 'src/app/core/services/tune-actions/tune-actions.service';
import { SubSink } from 'subsink';
import { Tune } from '../../interfaces/tune';
import { MatDialog } from '@angular/material/dialog';
import { EditTuneComponent } from '../../dialogs/edit-tune/edit-tune.component';
import { DeleteTuneComponent } from '../../dialogs/delete-tune/delete-tune.component';
import { EditBuyinfoComponent } from '../../dialogs/edit-buyinfo/edit-buyinfo.component';
import { ShareComponent } from '../../dialogs/share/share.component';
import { SearchExternalComponent } from '../../dialogs/search-external/search-external.component';
import { RepostComponent } from '../../dialogs/repost/repost.component';
import { RemoveFromSavedComponent } from '../../dialogs/remove-from-saved/remove-from-saved.component';
import { Router } from '@angular/router';
import { DeleteFromFeedComponent } from '../../dialogs/delete-from-feed/delete-from-feed.component';
import { AngularFireDatabase } from '@angular/fire/database';
import { ReportComponent } from '../../dialogs/report/report.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentService } from 'src/app/core/services/comment/comment.service';
import { Comment } from '../../interfaces/comment';
import { LikesTotalComponent } from '../../dialogs/likes-total/likes-total.component';
import { EditRepostDescriptionComponent } from '../../dialogs/edit-repost-description/edit-repost-description.component';
import { AuthRequiredComponent } from '../../dialogs/auth-required/auth-required.component';

@Component({
  selector: 'app-tune',
  templateUrl: './tune.component.html',
  styleUrls: [
    './styles/tune.component.scss',
    './styles/tune-small.component.scss',
    './styles/tune-mini.component.scss',
    './styles/tune-indi.component.scss',
    './styles/tune-sidebar.component.scss',
  ],
})
export class TuneComponent implements OnInit {
  @Output('deleteFromList') deleteFromList: EventEmitter<any> =
    new EventEmitter();

  private subs = new SubSink();

  @Input() tune!: Tune;
  @Input() layout!: string;
  @Input() page!: string;
  @Input() index!: number;
  @Input() listKey!: string;

  uid!: string | null;
  activeTune!: Tune | null;
  progress: number = 0;
  showSnippetIcon: boolean = false;
  showCommentInput: boolean = false;
  commentForm!: FormGroup;
  commentPlaceholder: string = 'Post a comment';
  isReply: boolean = false;
  replyInfo: any = null;
  commentsVisible: number = 3;
  repliesVisible: any = {};

  constructor(
    private authService: AuthService,
    private tuneActionsService: TuneActionsService,
    private youtubeVideoService: YoutubeVideoService,
    private dialog: MatDialog,
    private ch: ChangeDetectorRef,
    private router: Router,
    private db: AngularFireDatabase,
    private formBuilder: FormBuilder,
    private commentService: CommentService
  ) {
    this.subs.add(
      this.authService.uid$.subscribe((uid) => {
        this.uid = uid ? uid : null;
      })
    );

    this.commentForm = this.formBuilder.group({
      message: ['', Validators.compose([Validators.maxLength(450)])],
    });
  }

  ngOnInit(): void {
    // Listen to the active tune
    this.subs.add(
      this.youtubeVideoService.activeTune$.subscribe((activeTune: Tune) => {
        if (activeTune.key === this.tune.key) {
          this.activeTune = { ...activeTune };
          if (this.activeTune.progressSnippet)
            this.progress =
              Math.round(this.activeTune?.progressSnippet * 100) / 100;
          this.ch.detectChanges();
        } else {
          this.activeTune = null;
          this.progress = 0;
        }
      })
    );
  }

  goTo = (type: string, id?: string, isBlog?: boolean, tunrBlog?: boolean) => {
    if (isBlog && type === 'user') {
      this.router.navigate([`/${tunrBlog ? 'blog' : 'inspirational'}/${id}`]);
      return;
    }

    if (type === 'user') {
      this.router.navigate([`/profile/${id}`]);
    }

    if (type === 'tune') {
      this.router.navigate([`/tunes/${id}`]);
    }
  };

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  showPlay = () => {
    this.showSnippetIcon = true;
  };

  hidePlay = () => {
    this.showSnippetIcon = false;
  };

  playTune = (type: string) => {
    this.youtubeVideoService.playTune(this.tune, type, this.listKey);

    // Remove from didnotlisten
    if (this.tune?.didnotlisten)
      this.tuneActionsService.listenedToTune(this.tune.key);

    if (this.page === 'home') {
      this.tuneActionsService.lastListenedDate();
    }
  };

  hideTune = () => {
    this.tuneActionsService.hideTune(this.tune);
  };

  deleteFromFeed = () => {
    const dialogRef = this.dialog.open(DeleteFromFeedComponent, {
      data: { tune: this.tune },
      width: '90%',
      maxWidth: 600,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || !result.key) return;

      const key = result.originalKey ? result.originalKey : result.key;

      this.db
        .object('/feeds/' + this.uid + '/' + key)
        .remove()
        .then(() => {
          this.tuneActionsService.hideTune(this.tune);
        });
    });
  };

  reportTune = () => {
    const dialogRef = this.dialog.open(ReportComponent, {
      data: { tune: this.tune },
      width: '90%',
      maxWidth: 400,
    });

    dialogRef.afterClosed().subscribe((result) => {
      const data: any = result[0];
      const tune: Tune = data.tune;
      const reportObject: any = result[1];
      let reportValues: string[] = [];

      if (reportObject) {
        Object.keys(reportObject).forEach((key) => {
          if (reportObject[key]) reportValues.push(key);
        });
      }

      if (tune && tune.key && reportValues.length > 0) {
        this.tuneActionsService.reportTune(tune, reportValues);
      }
    });
  };

  openEditDialog = () => {
    this.dialog.open(EditTuneComponent, {
      data: { tune: this.tune },
      width: '90%',
      maxWidth: 600,
    });
  };

  openEditRepostDescriptionDialog = () => {
    this.dialog.open(EditRepostDescriptionComponent, {
      data: { tune: this.tune },
      width: '90%',
      maxWidth: 600,
    });
  };

  openEditBuyinfoDialog = () => {
    this.dialog.open(EditBuyinfoComponent, {
      data: { tune: this.tune },
      width: '90%',
      maxWidth: 600,
    });
  };

  openDeleteTuneDialog = () => {
    this.dialog.open(DeleteTuneComponent, {
      data: { tune: this.tune },
      width: '90%',
      maxWidth: 600,
    });
  };

  openLikesTotal = () => {
    this.dialog.open(LikesTotalComponent, {
      data: { tune: this.tune },
      width: '90%',
      maxWidth: 600,
    });
  };

  openShareDialog = () => {
    const url = `https://www.tunrmusic.com/tunes/${this.tune.key}`;

    this.dialog.open(ShareComponent, {
      data: { url: url },
      width: '90%',
      maxWidth: 600,
    });
  };

  openSearchExternalDialog = () => {
    if (!this.tune) return;

    this.dialog.open(SearchExternalComponent, {
      data: { uploadTitle: this.tune.uploadTitle },
      width: '90%',
      maxWidth: 600,
    });
  };

  openBuyLink = (link: string | undefined) => {
    const formattedLink =
      link && (link.startsWith('http://') || link.startsWith('https://'))
        ? link
        : 'http://' + link;

    if (formattedLink) {
      (window as any).open(formattedLink, '_blank');
    }
  };

  repost = async () => {
    const tune = { ...this.tune };
    if (tune.repostDescription && tune.repostAuthor !== this.uid)
      delete tune.repostDescription;

    this.dialog.open(RepostComponent, {
      data: { tune },
      width: '90%',
      maxWidth: 600,
    });
  };

  openAuthRequired = (type: string) => {
    this.dialog.open(AuthRequiredComponent, {
      data: { type },
      width: '90%',
      maxWidth: 600,
    });
  };

  deleteRepost = () => {
    this.dialog.open(DeleteTuneComponent, {
      data: {
        tune: this.tune,
        type: 'repost',
      },
      width: '90%',
      maxWidth: 600,
    });
  };

  presentRemove = () => {
    const dialogRef = this.dialog.open(RemoveFromSavedComponent, {
      data: { tune: this.tune },
      width: '90%',
      maxWidth: 600,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.key) {
        this.deleteFromList.emit(result.key);
        this.tuneActionsService.unsaveTune(result.key);
      }
    });
  };

  likeTune = () => {
    this.tuneActionsService.likeTune(this.tune);
  };

  dislikeTune = () => {
    this.tuneActionsService.dislikeTune(this.tune);
  };

  saveTune = () => {
    this.tuneActionsService.saveTune(this.tune);
  };

  unsaveTune = () => {
    if (this.tune.key) this.tuneActionsService.unsaveTune(this.tune.key);
  };

  toggleCommentInput = () => {
    this.showCommentInput = !this.showCommentInput;
  };

  preventEnter = (e: any) => {
    e.preventDefault();
  };

  postComment = (commentForm: FormGroup) => {
    if (!this.uid || !commentForm.valid) return;

    const message = commentForm.value.message;
    if (this.tune.key && message && message.length > 0)
      this.commentService.postComment(message, this.tune, this.replyInfo);

    this.commentForm.get('message')?.reset();
    this.commentForm.controls['message'].setErrors(null);

    this.isReply = false;
    this.replyInfo = null;
  };

  closeReply = () => {
    this.isReply = false;
    this.replyInfo = null;
    this.showCommentInput = false;
  };

  openReplyToComment = (comment: Comment) => {
    this.commentPlaceholder = `Reply to ${comment.username}`;
    this.showCommentInput = true;
    this.isReply = true;
    this.replyInfo = {
      author: comment.author,
      username: comment.username,
      parentCommentKey: comment.parentCommentKey
        ? comment.parentCommentKey
        : comment.commentKey,
    };
  };

  showMoreComments = () => {
    this.commentsVisible += 3;
  };

  getEnd = () => {
    // const key = reply.parentCommentKey;

    // console.log('key', key);
    return 3;
  };

  loadMoreReplies = (comment: Comment) => {
    if (!comment.commentKey) return;

    if (this.repliesVisible[comment.commentKey]) {
      this.repliesVisible[comment.commentKey] = this.repliesVisible[
        comment.commentKey
      ] += 3;
    } else {
      this.repliesVisible[comment.commentKey] = 6;
    }
  };
}
