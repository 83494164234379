import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ThemeService } from 'src/app/core/services/theme/theme.service';
import { PlatformService } from 'src/app/core/services/platform/platform.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { AuthService } from '../../services/auth/auth.service';
import { SubSink } from 'subsink';
import { YoutubeVideoService } from '../../services/youtube-video/youtube-video.service';
import _debounce from 'lodash/debounce';
import { User } from 'src/app/features/shared/interfaces/user';
import { filter } from 'rxjs/operators';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { AuthRequiredComponent } from 'src/app/features/shared/dialogs/auth-required/auth-required.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  private subs = new SubSink();

  @ViewChild('drawer') drawer!: MatDrawer;
  
  isServer: boolean = true;

  uid!: string | null;
  user!: User | null;
  isDarkMode!: boolean;
  showSidebar: boolean = false;
  handsetLoaded: boolean = false;
  mediaplayerVisible: boolean = false;
  searchQuery: string = "";
  searchList: any[] = [];
  searchLoading: boolean = false;
  noSearchResults: boolean = false;
  showResults: boolean = false;
  currentRoute: string = "";
  isOnOwnProfile: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
    map(result => {
      this.handsetLoaded = true;
      return result.matches
    }),
    shareReplay()
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private themeService: ThemeService,
    private platformService: PlatformService,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private youtubeVideoService: YoutubeVideoService,
    @Inject(PLATFORM_ID) platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (this.platformService.isBrowser()) {
      this.themeService.initTheme();
    }

    this.isServer = isPlatformServer(platformId);

    this.isDarkMode = this.themeService.isDarkMode();

    this.subs.add(this.authService.user$.subscribe(user => {
      this.user = user ? user : null;
      this.isOnOwnProfile = (this.currentRoute === `/profile/${this.user?.username}`) ? true : false;
    }));

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event: any) => {
        this.currentRoute = event.url;
        this.isOnOwnProfile = (this.currentRoute === `/profile/${this.user?.username}`) ? true : false;
      });
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showSidebar = this.activatedRoute!.firstChild!.snapshot.data.showSidebar !== false;
      }
    });

    this.subs.add(this.authService.uid$.subscribe(uid => {
      if (uid) {
        this.uid = uid;
      } else {
        this.uid = null;
      }
    }))

    this.subs.add(this.youtubeVideoService.mediaplayerVisible$.subscribe((visible: boolean) => {
      this.mediaplayerVisible = visible;
    }))
  }

  openAuthRequired = (type: string) => {
    this.dialog.open(AuthRequiredComponent, {
      data: { type },
      width: '90%',
      maxWidth: 600
    });
  }

  drawerChanged = (open: any) => {
    if (!this.isServer) {
      if (window.innerWidth < 960) {
        if (open) {
          this.document.body.classList.add('stop-scroll');
        } else {
          this.document.body.classList.remove('stop-scroll');
        }
      } else {
        this.document.body.classList.remove('stop-scroll');
      }
    }
  }

  logout = () => {
    return this.authService.logout();
  }
  
  toggleDrawer = () => {
    this.drawer.toggle();
  }

  goTo = (path: string) => {
    this.router.navigate([`${path}`])
  }

  minimizeMediaplayer = () => {
    this.youtubeVideoService.minimizeMediaplayer();
  }

  closeSidenav = () => {
    if (!this.isServer) {
      if (window.innerWidth < 960) {
        this.drawer.close();
      }
    }
  }

  toggleDarkMode = () => {
    this.isDarkMode = this.themeService.isDarkMode();
    const theme = this.isDarkMode ? 'light-mode' : 'dark-mode';
    this.themeService.updateTheme(theme);
  }
}
