import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TunesDataService } from 'src/app/core/services/tunes-data/tunes-data.service';
import { SubSink } from 'subsink';
import { Tune } from '../../interfaces/tune';
import { User } from '../../interfaces/user';
import _first from 'lodash/first';
import _slice from 'lodash/slice';
import _concat from 'lodash/concat';
import { TuneActionsService } from 'src/app/core/services/tune-actions/tune-actions.service';
import { YoutubeVideoService } from 'src/app/core/services/youtube-video/youtube-video.service';

@Component({
  selector: 'app-tunes-list',
  templateUrl: './tunes-list.component.html',
  styleUrls: ['./tunes-list.component.scss']
})
export class TunesListComponent implements OnInit {

  @Input() ref!: string;
  @Input() orderBy!: string;
  @Input() layout!: string;
  @Input() batch!: number;
  @Input() page!: string;
  @Input() popularRange!: Number;
  @Input() index!: Number;
  @Input() listKey!: string;

  private subs = new SubSink();

  uid!: string | null;
  user!: User;
  lastKey: string = '';
  tunesList = new BehaviorSubject<Tune[]>([]);
  finished: boolean = false;
  tunesListIsEmpty!: boolean;
  tunesListIsLoading: boolean = true;
  sortBy!: string;
  previousTune!: Tune;
  nextTune!: Tune;

  constructor(
    private authService: AuthService,
    private db: AngularFireDatabase,
    private tunesDataService: TunesDataService,
    private tuneActionsService: TuneActionsService,
    private youtubeVideoService: YoutubeVideoService
  ) {
    this.subs.add(this.authService.uid$.subscribe(uid => {
      if (uid) {
        this.uid = uid;
      } else {
        this.uid = null;
        //this.tunesList.next([]);
      }
    }))
  }

  ngOnInit() {
    if (this.page === 'popular') {
      this.getPopular();
    } else {
      if (this.orderBy) {
        this.getFullTunes();
      } else {
        this.getTunesByKeys();
      }
    }

    // Sorting of the tunes in the view
    if (this.page !== 'popular') {
      this.sortBy = this.orderBy ? this.orderBy : 'date';
    }
    
    this.tuneActionsService.hideTuneObs$.subscribe((tune: Tune) => {
      if (tune.isRepost) {
        this.deleteFromList(tune.originalKey);
      } else {
        this.deleteFromList(tune.key);
      }
    })

    this.youtubeVideoService.setSiblings$.subscribe((tune: Tune) => {
      this.setTuneSiblings(tune);
    })
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  setTuneSiblings = (tune: Tune) => {
    if (tune.listKey === this.listKey) {
      const currentTunes = this.tunesList.getValue();

      const currentTuneIndex = currentTunes.findIndex(currentTune => {
        if (tune.isRepost && currentTune.key === tune.key && currentTune.originalKey === tune.originalKey) {
          return true;
        } 
        if (!tune.isRepost && (currentTune.key === tune.key) && !currentTune.originalKey) {
          return true;
        } 
        return false;
      });

      const previousTune = currentTunes[currentTuneIndex - 1] ? currentTunes[currentTuneIndex - 1] : null;
      const nextTune = currentTunes[currentTuneIndex + 1] ? currentTunes[currentTuneIndex + 1] : null;
  
      this.youtubeVideoService.setPreviousTune(previousTune);
      this.youtubeVideoService.setNextTune(nextTune);
  
      if (!currentTunes[currentTuneIndex + 2] && this.page !== 'popular') {
        if (this.orderBy) {
          this.getFullTunes(false);
        } else {
          this.getTunesByKeys(false);
        }
      }
    } 
  }


  getTunesByKeys = (isRefresh?: boolean) => {

    if (isRefresh) this.lastKey = '';

    this.tunesDataService.getTunes(`${this.ref}`, this.batch + 1, this.lastKey, this.orderBy).pipe(
      tap(async (result: any) => {

        let newKeys: any[] = [];

        if (result.length === 0) {
          this.tunesListIsEmpty = true;
          this.tunesListIsLoading = false;
        } else if (result.length < this.batch + 1) {
          newKeys = _slice(result, 0, this.batch);
        } else {
          newKeys = _slice(result, 1, this.batch + 1);
        }

        // Set the the lastKey for the next request
        if (newKeys.length > 0) {
          this.lastKey = _first(newKeys);
        }

        // Last item of the database is reached
        if (this.lastKey === _first(newKeys)) {
          this.finished = true;
        }

        const currentKeys = this.tunesList.getValue().map((currentTune: any) => currentTune.originalKey ? currentTune.originalKey : currentTune.key);

        // Only request data from tunes that are new
        newKeys = newKeys.filter(newKey => !currentKeys.includes(newKey));

        const combinedKeys = [...currentKeys, ...newKeys];

        // Check if there is a tune that is deleted. If so, remove from feed
        combinedKeys.forEach(key => {
          this.subs.add(this.db.object(`${this.ref}/${key}`).valueChanges().subscribe(value => {
            if (!value) {
              this.deleteFromList(key);
            }
          }));
        });

        let promises: any[] = [];

        newKeys.forEach((key: any) => {
          // Get the data from each tune and push it the the array of promises
          const promise = this.tunesDataService.getTuneData(key)!.pipe(take(1)).toPromise().then(data => data);
          promises.push(promise);

          // Listen for realtime events and update the feed accordingly
          this.subs.add(this.tunesDataService.getTuneData(key)!.subscribe(data => {
            let arr = this.tunesList.getValue();
            arr.forEach((tune, index) => {
              const newTuneKey = (tune.originalKey && tune.isRepost) ? tune.originalKey : tune.key;
              if (newTuneKey === key) {
                arr[index] = data;
              };
            });
          }));
        });

        let newFullTunes = await Promise.all(promises);
        newFullTunes = newFullTunes.filter(tune => tune && tune.key);

        const currentTunes = this.tunesList.getValue();

        let newCombinedTunes = _concat(currentTunes, newFullTunes);

        // Filter duplicates, but not if it's a repost
        newCombinedTunes = newCombinedTunes.filter((tune, index, array) => array.findIndex(t => t.key == tune.key && t.isRepost == tune.isRepost) == index);

        this.tunesList.next(newCombinedTunes);
        
        this.tunesListIsLoading = false;

      }), take(1))
      .subscribe();
  };

  getFullTunes = (isRefresh?: boolean) => {

    if (isRefresh) this.lastKey = '';

    this.tunesDataService.getTunes(`${this.ref}`, this.batch + 1, this.lastKey, this.orderBy).pipe(
      tap(async (result: any[]) => {

        let newTunes: any[] = [];

        if (result.length === 0) {
          this.tunesListIsEmpty = true;
        } else if (result.length < this.batch + 1) {
          newTunes = _slice(result, 0, this.batch);
        } else {
          newTunes = _slice(result, 1, this.batch + 1);
        }

        // Set the the lastKey for the next request
        if (result.length > 0) {
          this.lastKey = _first(result)[this.orderBy];
        }

        // Last item of the database is reached
        if (!newTunes || (_first(newTunes) && this.lastKey === _first(newTunes)[this.orderBy])) {
          this.finished = true;
        }

        const currentTuneKeys = this.tunesList.getValue().map((currentTune: any) => currentTune.key);

        // Only request data from users that are new
        newTunes = newTunes.filter(newTune => !currentTuneKeys.includes(newTune));

        // Check if the tune is reposted (for liked and archived)
        newTunes.forEach(newTune => {
          this.subs.add(this.db.object(`/repostedTunes/${this.uid}/${newTune.key}`).valueChanges().subscribe(reposted => {
            newTune.reposted = reposted ? true : false;
          }));
        })

        const currentTunes = this.tunesList.getValue();
        this.tunesList.next(_concat(currentTunes, newTunes));

        this.tunesListIsLoading = false;

      }), take(1))
      .subscribe();
  };

  ngOnChanges(changes: SimpleChanges) {
    if (this.page === 'popular' && changes?.popularRange && changes.popularRange?.previousValue) {
      this.tunesList.next([]);
      this.getPopular();
    }
  }

  getPopular = () => {
    const timeNow = Date.now();
    const timeAgo = this.popularRange === 30 ? 2592000000 : this.popularRange === 7 ? 604800000 : 0;
    let popularTunesListData: any[] = [];

    this.subs.add(this.db.list(`/topTunes`, ref => ref.orderByKey().limitToLast(200)).valueChanges().pipe(
      tap((popularTunes: Tune[] | any) => {
        popularTunes.forEach((popularTune: Tune | any) => {
          if ((timeNow - timeAgo) < popularTune.date) {
            popularTune.likesCount = popularTune.likes;

            if (popularTune.likes > 0) {
              popularTunesListData.push(popularTune);
            }
          }
        })

      }),
      tap(() => {

        popularTunesListData.sort((a: any, b: any) => {
          if (a.likes < b.likes) return 1;
          else if (a.likes > b.likes) return -1;
          else return 0;
        })

        popularTunesListData = popularTunesListData.slice(0, 20);

        popularTunesListData.forEach((popularTune: Tune) => {
          if (!popularTune.blog) {
            this.subs.add(this.db.object(`/images/${popularTune.author}/image`).valueChanges().pipe(take(1)).subscribe((img: any) => {
              popularTune.image = img;
            }))
          }
        })

        this.tunesList.next(popularTunesListData);
        this.tunesListIsLoading = false;

      }),
      take(1)
    ).subscribe());
  }

  deleteFromList = (key: string | undefined) => {
    if (!key) return; 

    let arr = this.tunesList.getValue();
    arr.forEach((tune, index) => {
      const newTuneKey = (tune.originalKey && tune.isRepost && (this.page !== 'saved')) ? tune.originalKey : tune.key;
      if (newTuneKey === key) {
        arr.splice(index, 1);
      };
    });

    try {
      if (this.tunesList.getValue().length == 0) this.tunesListIsEmpty = true;
    } catch (err) { console.log('err', err) }
  }

  trackByFn(index: number) {
    return index;
  }

  loadMore = (isRefresh?: boolean) => {
    if (this.orderBy) {
      this.getFullTunes(isRefresh);
    } else {
      this.getTunesByKeys(isRefresh);
    }
  }
}

