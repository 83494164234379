import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NotificationsService } from 'src/app/core/services/notifications/notifications.service';
import { SubSink } from 'subsink';
import { map, take, tap } from 'rxjs/operators';
import _first from 'lodash/first';
import _slice from 'lodash/slice';
import _concat from 'lodash/concat';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
  selector: 'app-navigation-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  private subs = new SubSink();

  @ViewChild('notificationOptionsTrigger') notificationOptionsTrigger!: MatMenuTrigger;
  
  uid!: string | null;
  notificationsList = new BehaviorSubject<Notification[]>([]);
  batch: number = 6;
  lastDate: number = 0;
  loading: boolean = true;
  noNotifications: boolean = false;
  notificationsCount: number = 0;

  constructor(
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private db: AngularFireDatabase,
  ) { 
    this.subs.add(this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;

      if (this.uid) {
        this.listenToNotifications();
        this.getNewNotifications();
      } else {
        this.lastDate = 0;
        this.notificationsList.next([]);
      }
    }));
  }
   
  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  markAllAsRead = (event: any) => {
    event.stopPropagation();
    this.notificationOptionsTrigger.closeMenu();

    const notifications$: any = this.db.list(`/notifications/${this.uid}`).snapshotChanges().pipe(
      map((notifications:any) => {
        return notifications.map((c: any) => ({ notificationKey: c.payload.key, ...c.payload.val() }));     
      }),
      take(1));

    notifications$.subscribe((notifications: any[]) => {
      notifications.forEach((notification: any) => {
        this.db.object(`/notifications/${this.uid}/${notification.notificationKey}/didnotsee`).set(null);
      })
    });

    let arr = this.notificationsList.getValue();
    arr.forEach((notification: any, index) => {
      notification.didnotsee = false;
    });
  }

  getNewNotifications = (event?: any) => {
    this.subs.add(this.notificationsService.getNewNotifications(this.batch, this.lastDate).pipe(
      tap((notifications: any[]) => {
        
        if (!notifications || notifications.length < 1) {
          this.noNotifications = true;
          this.loading = false;
          return;
        } else {
          this.noNotifications = false;
        }

        // Set the lastDate in preparation for the next query
        this.lastDate = _first(notifications)['date'];

        let newNotifications = [];

        if (notifications.length < this.batch + 1) {
          newNotifications = _slice(notifications, 0, this.batch);
        } else {
          newNotifications = _slice(notifications, 1, this.batch + 1);
        }

        // Get current notifications in BehaviorSubject
        const currentNotifications = this.notificationsList.getValue();

        // Check which notifications are new and only push those
        const currentKeys = this.notificationsList.getValue().map((currentTune: any) => currentTune.originalKey);
        newNotifications = newNotifications.filter(newNotif => !currentKeys.includes(newNotif.originalKey));
        const newKeys = newNotifications.map((currentTune: any) => currentTune.originalKey); 
        const combinedKeys = [...currentKeys, ...newKeys];

        // Check if there is a notification that is deleted. If so, remove from notifications
        combinedKeys.forEach((key: string) => {
          this.subs.add(this.db.object(`/notifications/${this.uid}/${key}`).valueChanges().subscribe(value => {
            if (!value) {
              this.deleteFromList(key);
            }
          }));
        });

        this.notificationsList.next(_concat(currentNotifications, newNotifications));

        setTimeout(() => {
          this.loading = false;
        }, 300);

        // Close the onScroll event
        if (event) {
          event.target.complete();
        }

      })
    ).subscribe());
  }

  deleteFromList = (author: string) => {
    let arr = this.notificationsList.getValue();
    arr.forEach((notification: any, index) => {
      if (notification.author === author) {
        arr.splice(index, 1);
      };
    });
  }

  removeNotificationsCount = () => {
    this.db.list(`/notificationsCount/${this.uid}`).remove();
  }

  listenToNotifications = async () => {
    this.subs.add(this.db.list(`/notificationsCount/${this.uid}`).valueChanges().subscribe(notifications => {
      this.notificationsCount = notifications.length;
    }))
  }
}
