/* THIS SHOULD BE USED WHEN USING THE PROD DATABASE ON THE PROD ENVIRONMENT */
export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyA37W0a6Z5e-tg1eS5P3q6FAbDpPYv9NGI",
    authDomain: "tunr.firebaseapp.com",
    databaseURL: "https://tunr.firebaseio.com",
    projectId: "firebase-tunr",
    storageBucket: "firebase-tunr.appspot.com",
    messagingSenderId: "552189185397",
    appId: "1:552189185397:web:d32d254ed9c065924cd73c",
    measurementId: "G-3FJ84FFW28"
  }
};


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* THIS SHOULD BE USED WHEN USING THE DEV DATABASE ON THE DEV ENVIRONMENT */
// export const environment = {
//   production: true,
//   firebaseConfig: {
//     apiKey: "AIzaSyBrU_3ISYhR1DxleZOCIg6DSDoJIzSydmU",
//     authDomain: "tunr-dev.firebaseapp.com",
//     projectId: "tunr-dev",
//     storageBucket: "tunr-dev.appspot.com",
//     messagingSenderId: "231258128353",
//     appId: "1:231258128353:web:fdeb62c4d80f304c001ca0"
//   }
// };

/* THIS SHOULD BE USED WHEN USING THE PROD DATABASE ON THE DEV ENVIRONMENT */
// export const environment = {
//   production: true,
//   firebaseConfig: {
//     apiKey: "AIzaSyA37W0a6Z5e-tg1eS5P3q6FAbDpPYv9NGI",
//     authDomain: "tunr-dev.firebaseapp.com",
//     databaseURL: "https://tunr.firebaseio.com",
//     projectId: "firebase-tunr",
//     storageBucket: "firebase-tunr.appspot.com",
//     messagingSenderId: "552189185397",
//     appId: "1:552189185397:web:d32d254ed9c065924cd73c",
//   }
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
