<div class="sidebar">

  <!-- Inspirational overview -->
  <div class="sidebar__section" *ngIf="visibleSections.includes('otherInspirationalArtists')">
    <div class="sidebar__section__title">
      Check these inspirational artists
    </div>
    <div class="sidebar__section__content sidebar__section__content--other-inspirational">
      <div class="sidebar__section__content sidebar__section__content--other-inspirational__list">
        <mat-card class="sidebar__section__content--other-inspirational__list__artist"
          *ngFor="let otherArtist of otherInspirationalArtists" routerLink="/inspirational/{{otherArtist?.name}}"
          matRipple>
          <div class="sidebar__section__content--other-inspirational__list__artist__image">
            <img [src]="otherArtist?.croppedImage" />
          </div>
          <div class="sidebar__section__content--other-inspirational__list__artist__copy">
            <div class="sidebar__section__content--other-inspirational__list__artist__copy__number">
              <b>{{otherArtist?.number}}</b></div> {{otherArtist?.fullname}}
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- Inspirational artist -->
  <div class="sidebar__section" *ngIf="visibleSections.includes('inspirationalArtists')">
    <div class="sidebar__section__title">
      Latest artists
    </div>
    <div class="sidebar__section__content sidebar__section__content--inspirational-artist">
      <div class="sidebar__section__content sidebar__section__content--inspirational-artist__list">
        <mat-card class="sidebar__section__content--inspirational-artist__list__artist"
          *ngFor="let artist of inspirationalArtists | async" routerLink="/inspirational/{{artist?.name}}" matRipple>
          <div class="sidebar__section__content--inspirational-artist__list__artist__image">
            <img [src]="artist?.croppedImage" />
          </div>
          <div class="sidebar__section__content--inspirational-artist__list__artist__copy">
            <div class="sidebar__section__content--inspirational-artist__list__artist__copy__number">
              <b>{{artist?.number}}</b></div> {{artist?.fullname}}
          </div>
        </mat-card>
        <mat-card class="sidebar__section__content--inspirational-artist__list__load-more"
          (click)="getInspirationalArtists()" *ngIf="!artistsFinished">
          Load more
        </mat-card>
      </div>
    </div>
  </div>

  <!-- Recently followed -->
  <div class="sidebar__section"
    *ngIf="visibleSections.includes('recentlyFollowing') && followingRef && !recentlyFollowingEmpty">
    <div class="sidebar__section__title">
      User recently followed
    </div>
    <div class="sidebar__section__content sidebar__section__content--recently-following">
      <app-users-list [ref]="followingRef" [batch]="3"></app-users-list>
    </div>
  </div>

  <!-- Recently followed by -->
  <div class="sidebar__section"
    *ngIf="visibleSections.includes('recentlyFollowedBy') && followersRef && !recentlyFollowersEmpty">
    <div class="sidebar__section__title">
      User got recently followed by
    </div>
    <div class="sidebar__section__content sidebar__section__content--recently-followed-by">
      <app-users-list [ref]="followersRef" [batch]="3"></app-users-list>
    </div>
  </div>

  <!-- Other liked tunes -->
  <div class="sidebar__section" *ngIf="visibleSections.includes('otherLikedTunes') && !otherTunesEmpty">
    <div class="sidebar__section__title">
      <span *ngIf="!isIndiTuneBlog">User also liked</span>
      <span *ngIf="isIndiTuneBlog">Artist also posted</span>
    </div>
    <div class="sidebar__section__content sidebar__section__content--other-liked-tunes">
      <div *ngFor="let otherTune of otherTunes | slice:0:5">
        <app-tune [tune]="otherTune" [layout]="'sidebar'" [listKey]="'otherTunes'"></app-tune>
      </div>
    </div>
  </div>

  <!-- Hashtags -->
  <div class="sidebar__section" *ngIf="visibleSections.includes('trendingHashtags')">
    <div class="sidebar__section__title">
      Trending hashtags
    </div>
    <div class="sidebar__section__content sidebar__section__content--hashtags">
      <div class="sidebar__section__content--hashtags__list">
        <mat-card class="sidebar__section__content--hashtags__list__hashtag" *ngFor="let hashtag of hashtagsList"
          routerLink="/hashtag/{{hashtag?.hashtagId}}" matRipple>
          <div class="sidebar__section__content--hashtags__list__hashtag__icon">
            <span class="fas fa-hashtag"></span>
          </div>
          <div class="sidebar__section__content--hashtags__list__hashtag__copy">
            <div class="sidebar__section__content--hashtags__list__hashtag__copy__name">
              {{hashtag.hashtagId}}
            </div>
            <div class="sidebar__section__content--hashtags__list__hashtag__copy__tunes">
              {{hashtag.count}} tunes
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- Recently posted -->
  <div class="sidebar__section" *ngIf="visibleSections.includes('recentTunes')">
    <div class="sidebar__section__title">
      Recently posted
    </div>
    <div class="sidebar__section__content sidebar__section__content--recent-tunes">
      <div *ngFor="let tune of recentTunesList | slice:0:4">
        <app-tune [tune]="tune" [layout]="'sidebar'" [listKey]="'recent-tunes'"></app-tune>
      </div>
    </div>
  </div>

  <!-- Activity -->
  <div class="sidebar__section" *ngIf="visibleSections.includes('activity') && !activityEmpty">
    <div class="sidebar__section__title">
      Activity
    </div>
    <div class="sidebar__section__content sidebar__section__content--activity">
      <div *ngFor="let activity of activityList | async | orderBy: 'date'">
        <app-notification [notification]="activity"></app-notification>
      </div>
    </div>
  </div>

  <!-- Recently followed by -->
  <div class="sidebar__section" *ngIf="visibleSections.includes('goMobile')">
    <div class="sidebar__section__title">
      Go mobile
    </div>
    <div class="sidebar__section__content sidebar__section__content--use-app">
      <app-use-app></app-use-app>
    </div>
  </div>

</div>
