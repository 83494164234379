<div class="users-list">
  <div class="users-list__loading" *ngIf="usersListLoading">
    <mat-progress-spinner color="primary" mode="indeterminate" strokeWidth="4" diameter="40"></mat-progress-spinner>
  </div>
  
  <div [ngClass]="{'hide': usersListLoading}">
    <div *ngFor="let user of usersList | async ; trackBy: trackByFn ; let i = index">
      <app-users-list-user [user]="user" (onClickUser)="handleClickUser()"></app-users-list-user>
    </div>
  </div>
  
</div>
