<div class="likes-total">
    <div mat-dialog-title class="likes-total__title">
        <h4>Likes</h4>
        <button mat-icon-button aria-label="Close dialog" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="likes-total__list">
      <app-users-list (onClickUser)="handleClickUser()" [ref]="usersListRef" [batch]="batch"></app-users-list>
    </div>
</div>
