import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase, SnapshotAction } from '@angular/fire/database';
import { Notification } from '../../../features/shared/interfaces/notification';
import { Activity } from '../../../features/shared/interfaces/activity';
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  uid!: string | null;

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService
  ) { 
    this.authService.uid$.subscribe(uid => {
      this.uid = uid ? uid : null;
    })
  }

  getNewNotifications = (batch: number, lastDate: number): Observable<Notification[]> => {
    let notificationsRef: AngularFireList<Notification>;

    if (lastDate) {
      notificationsRef = this.db.list('/notifications/' + this.uid, ref =>
        ref.orderByChild('date').limitToLast(batch + 1).endAt(lastDate)
      );
    } else {
      notificationsRef = this.db.list('/notifications/' + this.uid, ref =>
        ref.orderByChild('date').limitToLast(batch + 1)
      );
    }

    return notificationsRef.snapshotChanges().pipe(
      map((notifications: SnapshotAction<Notification>[] | any): Notification[] =>
        notifications.map((c: any) => ({
          ...c.payload.val(),
          originalKey: c.payload.key, 
          type: 'newNotification',
          users: c.payload.val().users ? Object.keys(c.payload.val().users).map(key => c.payload.val().users[key]) : []
        }))
      ),
      map((notifications: Notification[]): Notification[] =>
        notifications.map(notification => {
          if (notification.users && notification.users.length > 0) {
            notification.latestUser = notification.users.reduce((prev:any, curr:any) => prev.date < curr.date ? prev : curr);
          }
          return notification;
        })
      ),
      map((notifications: Notification[]): Notification[] => 
        notifications.filter(notification => notification.latestUser)
      )
    )
  }

  getNewTuners = (batch: number, lastDate: number): Observable<Notification[]> => {
    
    let notificationsRef: AngularFireList<Notification>;

    if (lastDate) {
      notificationsRef = this.db.list('/notificationsTune/' + this.uid, ref =>
        ref.orderByChild('date').limitToLast(batch + 1).endAt(lastDate)
      );
    } else {
      notificationsRef = this.db.list('/notificationsTune/' + this.uid, ref =>
        ref.orderByChild('date').limitToLast(batch + 1)
      );
    }

    return notificationsRef.valueChanges().pipe(
      map((notifications: Notification[]): Notification[] =>
        notifications.map(notification => {
          notification.type = 'newTuner';
          return notification;
        })
      )
    )
  }

  getActivity = (batch: number, lastDate: number | undefined): Observable<Activity[]> => {
    
    let notificationsRef: AngularFireList<Activity>;

    if (lastDate) {
      notificationsRef = this.db.list('/activityLog/' + this.uid, ref =>
        ref.orderByChild('date').limitToLast(batch + 1).endAt(lastDate)
      );
    } else {
      notificationsRef = this.db.list('/activityLog/' + this.uid, ref =>
        ref.orderByChild('date').limitToLast(batch + 1)
      );
    }

    return notificationsRef.snapshotChanges().pipe(
      map((notifications: SnapshotAction<any>[]): Activity[] =>
        notifications.map(c => ({
          ...c.payload.val(),
          originalKey: c.payload.key, 
          type: 'activity'
        }))
      )
    )
  }
}
